import { createContext, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "./useLocalStorage"
import { useWebSocket } from "./useWebsocket"
import { jwtDecode } from "jwt-decode"

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [user, setUser, getValue] = useLocalStorage("user", null);
    const { startWebSocket } = useWebSocket()
    const navigate = useNavigate();

    const login = async (data) => {
        setUser(data);
        navigate("/leads");
        handleStartWebSocket(data)
    };

    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };

    const handleStartWebSocket = (data) => {
        const user = jwtDecode(data.credential)
        startWebSocket(user.email)
    }

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};