import { useDispatch } from "react-redux"
import Avatar from "../../Avatar"

import './styles.css'
import { selectPartner } from "../../../action/partners"

const PaperPartner = ({
    add,
    active,
    partner,
    disabled
}) => {
    const dispatch = useDispatch()

    const handleOnSelect = () => {
        if (add) {
            dispatch(selectPartner({ action: "new" }))
        } else {
            dispatch(selectPartner(partner))
        }
    }

    return (
        <button 
            disabled={disabled}
            onClick={handleOnSelect}
            className={`paper-partner-container ${active ? "active" : ""} ${disabled ? "disabled": ""}`} 
        >
            <Avatar label={add ? "+": partner.name[0]}/>
            <div className="paper-partner-content">
                <span>
                    {add ? "Adicionar novo parceiro" : partner.name}
                </span>
            </div>
        </button>
    )
}

export default PaperPartner