import { createSlice } from '@reduxjs/toolkit'

export const leadSlice = createSlice({
    name: "lead",
    initialState: {
        name: "",
        list: [],
        selected: {},
        messages: [],
        refresh: true
    },
    reducers: {
        setLeadName: (state, action) => {
            state.name = action.payload
        },
        setLastServiceSelected: (state, action) => {
            state.lastServiceSelected = action.payload
        },
        loadLeads: (state, action) => {
            state.list = action.payload
            state.refresh = false
        },
        selectLead: (state, action) => {
            state.selected = action.payload
        },
        updateLead: (state, action) => {
            const { payload } = action
            state.selected[payload.column] = payload.value
        },
        insertMessage: (state, action) => {
            state.messages.push(action.payload)
        },
        insertUserMessage: (state, action) => {
            const { uuidUser, messages } = action.payload
            const others = state.messages.filter(item => item.uuidUser !== uuidUser)

            console.log(others)
            console.log(messages)
            
            state.messages = [...others, ...messages]
        },
        insertLead: (state, action) => {
            state.list.unshift(action.payload)
        },
        loadMessages: (state, action) => {
            state.messages = action.payload
        },
        updateMessageStatus: (state, action) => {
            const { messageId, status } = action.payload

            state.messages = state.messages.map((message) =>
                message.messageId === messageId
                    ? { ...message, status }
                    : message
            )
        },
        updateMessageRead: (state, action) => {
            const { messageId, read } = action.payload

            state.messages = state.messages.map((message) =>
                message.messageId === messageId
                    ? { ...message, read }
                    : message
            )
        },
        updateMessage: (state, action) => {
            const { messageId, newMessage } = action.payload

            state.messages = state.messages.map((item) =>
                item.messageId === messageId
                    ? { ...item, message: newMessage, edited: true }
                    : item
            )
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadLeads,
    insertLead,
    selectLead,
    updateLead,
    setLeadName,
    loadMessages,
    updateMessage,
    insertMessage,
    insertUserMessage,
    updateMessageRead,
    updateMessageStatus,
    setLastServiceSelected
} = leadSlice.actions

export default leadSlice.reducer