import { createSlice } from '@reduxjs/toolkit'

export const solicitationSlice = createSlice({
    name: "solicitation",
    initialState: {
        list: [],
        selected: {},
        active: {},
        all: {
            loading: true,
            total: 0,
            values: [],
            columns: [
                "service",
                "createAt",
                "status",
                "stage",
                "paymentStatus",
                "difficulty"
            ]
        },
    },
    reducers: {
        insertSolicitation: (state, action) => {
            if (Array.isArray(action.payload)) state.list = action.payload
            else state.list.push(action.payload)
        },
        selectSolicitation: (state, action) => {
            state.selected = action.payload
        },
        pushSolicitation: (state, action) => {
            state.all.values = state.all.values.concat(action.payload.items)
            state.all.nextPage = action.payload.nextPage
            state.all.total = action.payload.total
            state.all.loading = false
        },
        setLoading: (state, action) => {
            state.all.loading = action.payload
        },
        refreshUser: (state, action) => {
            const uuidSolicitation = action.payload.uuidSolicitation

            state.all.values = state.all.values.map(item => {
                if (item.uuid === uuidSolicitation) {
                    item.user = action.payload.user
                }
                return item
            })
        },
        refreshProposals: (state, action) => {
            const uuidSolicitation = action.payload.uuidSolicitation

            state.all.values = state.all.values.map(item => {
                if (item.uuid === uuidSolicitation) {
                    item.serviceProposals = action.payload.proposals
                }
                return item
            })
        },
        setActiveSolcitation: (state, action) => {
            state.active = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    setActiveSolcitation,
    insertSolicitation,
    selectSolicitation,
    pushSolicitation,
    refreshProposals,
    setLoading,
    refreshUser
} = solicitationSlice.actions

export default solicitationSlice.reducer