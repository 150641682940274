import { useDispatch, useSelector } from "react-redux";
import BottomSheetMapping from "../../../components/BottomSheets/Mapping";
import Layout from "../../../components/Layout"
import MapView from "../../../components/MapView"
import { useEffect, useState } from "react";
import * as solicitationsService from '../../../services/solicitations'
import { insertSolicitation } from "../../../action/solicitations";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { jwtDecode } from "jwt-decode";
import RedirectServiceModal from "../../../components/Modal/RedirectService";
import { useWebSocket } from "../../../hooks/useWebsocket";
import PaperSolicitation from "../../../components/Paper/Solicitation";
import DoneVector from '../../../assets/vector/done.svg'

import './styles.css'

const Mapping = () => {
    const [ userStoreged ] = useLocalStorage("user", null)
    const origin = {lat: -19.9309777, lng: -43.9507109}

    const dispatch = useDispatch()
    const { startWebSocket } = useWebSocket()

    const providers = useSelector(state => Object.values(state.providers.list))
    const solicitations = useSelector(state => Object.values(state.solicitations.list))
    const solicitationSelected = useSelector(state => state.solicitations.selected)
    const connectionWss = useSelector(state => state.webSocket)

    const [device, setDevice] = useState()

    const detectDevice = () => {
        const userAgent = navigator.userAgent;
        const isMobileDevice = /Mobi|Android/i.test(userAgent)
        const isTabletDevice = /Tablet|iPad/i.test(userAgent)
      
        if (window.innerWidth <= 768 && isMobileDevice) return "mobile"
        else if (window.innerWidth > 768 && window.innerWidth <= 1024 && isTabletDevice) return "tablet"
        return "desktop"
    }

    useEffect(() => {
        setDevice(detectDevice())
    }, [])

    useEffect(() => {
        (async () => {
            const response = await solicitationsService.getAllActiveSolicitations()
            if (response) {
                dispatch(insertSolicitation(response))
            }
        })()
    }, [])

    useEffect(() => {
        if(!connectionWss.ready) {
            const user = jwtDecode(userStoreged.credential)
            startWebSocket(user.email)
        }
    }, [])      
    
    return (
        <>
            <RedirectServiceModal />
            <Layout>
                <div className="mapping-container">
                    <div className="mapping-sidebar-container">
                        {solicitations.length > 0 ? (
                            <>
                                <span className="mapping-sidebar-title"> Solicitações Ativas</span>
                                <div className="mapping-sidebar-solicitations-grid">
                                    {solicitations.map((item, index) => {
                                        return (
                                            <PaperSolicitation key={index} solicitation={item}/>
                                        )
                                    })}
                                </div>
                            </>
                        ) : (
                            <div className="mapping-sidebar-done">
                                <img src={DoneVector}/>
                                <span> Tudo calmo por aqui!! </span>
                            </div>
                        )}
                    </div>
                    {device !== "mobile" &&
                        <div className="mapping-content">
                            <MapView origin={origin} providers={providers} solicitation={solicitationSelected}/>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}

export default Mapping;