import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as solicitationService from '../../../../services/solicitations'

import { setActiveSolcitation } from '../../../../action/solicitations'

import NewSolicitation from './New'
import ActiveSolicitation from './Current'
import ApproveServiceProposalModal from '../../../../components/Modal/ApproveServiceProposal'

import './styles.css'

const Solicitation = () => {
    const dispatch = useDispatch()

    const lead = useSelector(state => state.lead.selected)
    const solicitationActive = useSelector(state => state.solicitations.active)

    console.log(solicitationActive)

    const getActiveSolicitationByLead = async () => {
        const response = await solicitationService.getActiveSolicitationByLead({
            uuidLead: lead.uuid
        })

        if (response) {
            dispatch(setActiveSolcitation(response.solicitation))
        } else {
            dispatch(setActiveSolcitation({}))
        }
    }

    useEffect(() => { (async () => { getActiveSolicitationByLead() })() }, [lead])

    return (
        <>
            <ApproveServiceProposalModal />
            <div className='leads-solicitation-container'>
                <NewSolicitation />
                {Object.keys(solicitationActive).length > 0 && <ActiveSolicitation/>}
            </div>
        </>
    )
}

export default Solicitation