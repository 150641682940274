import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { jwtDecode } from "jwt-decode"

import * as leadService from '../../../services/leads'
import * as chatService from '../../../services/chat'
import * as solicitationService from '../../../services/solicitations'

import { loadLeads, loadMessages } from "../../../action/leads"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useWebSocket } from "../../../hooks/useWebsocket"
import { loadServices } from "../../../action/services"

import ModalFinishContact from "../../../components/Modal/FinishContact"
import PaperLead from "../../../components/Paper/Leads"
import Layout from "../../../components/Layout"
import Solicitation from "./Solicitation"
import Chat from "./Chat"

import DoneVoctor from '../../../assets/vector/done.svg'

import './styles.css'

const Leads = () => {
    const dispatch = useDispatch()

    const { startWebSocket } = useWebSocket()
    const [ userStoreged ] = useLocalStorage("user", null)

    const leads = useSelector(state => state.lead.list)
    const leadSelected = useSelector(state => state.lead.selected)
    const refreshLeads = useSelector(state => state.lead.refresh)
    const connectionWss = useSelector(state => state.webSocket)

    const getServicesActive = async () => {
        const response = await solicitationService.getServicesActive()
        if (response) dispatch(loadServices(response))
    }

    useEffect(() => {
        if (refreshLeads) {
            (async() => {
                const response = await leadService.getLeads()
                if (response) {
                    dispatch(loadLeads(response))

                    const uuidLeads = response.map(item => item.uuid)
                    const response2 = await chatService.loadMessages(uuidLeads)
                    if (response2) dispatch(loadMessages(response2))
                }
            })()
        }
    }, [refreshLeads])

    useEffect(() => {
        if(!connectionWss.ready) {
            const user = jwtDecode(userStoreged.credential)
            startWebSocket(user.email)
        }
    }, [])

    useEffect(() => { (async() => { getServicesActive() })() }, [])

    return (
        <>
            <ModalFinishContact />
            <Layout>
                <div className="leads-container">
                    <div className="leads-contacts-content">
                        {leads.length > 0 ? (
                            <div>
                                <span className="leads-contacts-title">Leads:</span>
                                <div className="leads-contacts-grid">
                                    {leads.map((lead, index) => {
                                        return (
                                            <PaperLead 
                                                key={index} 
                                                lead={lead}
                                                active={leadSelected.uuid === lead.uuid} 
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div className="leads-contacts-done">
                                <img src={DoneVoctor} alt="" />
                                <span>Tudo calmo por aqui!</span>
                            </div>
                        )}
                    </div>
                    <div className="leads-chat-content">
                        {Object.keys(leadSelected).length > 0 ? (
                            <div className="leads-chat-grid">
                                <Chat />
                                <Solicitation />
                            </div>
                        ) : (
                            <div>
                                selecione uma conversa
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Leads