import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { openModal } from "../../../action/modal"

import * as serviceProposalService from '../../../services/serviceProposal'

import Button from "../../Button"
import InputText from "../../Input/Text"

import './styles.css'
import { updateFee } from "../../../action/serviceProposals"

const ApproveServiceProposalModal = () => {
    const dispatch = useDispatch()

    const modalActive = useSelector(state => state.modal.approveServiceProposal)
    const serviceProposal = useSelector(state => state.serviceProposal.selected)

    const [loading, setLoading] = useState(false)
    const [paymentOptions, setPaymentOptions] = useState({
        paymentPlace: "",
        paymentMethod: "",
        paymentStatus: "pending"
    })

    const paymentPlace = [
        {id: "onsite", label: "No local"},
        {id: "online", label: "Online"},
    ]

    const paymentMethods = [
        {id: "pix", label: "Pix"},
        {id: "credit_card", label: "Crédito"},
    ]

    const handleClose = () => {
        dispatch(openModal({modal: "approveServiceProposal", value: false}))
    }

    const handleSetPaymentOptions = (column, value) => {
        setPaymentOptions({
            ...paymentOptions,
            [column]: value
        })
    }

    const submmit = async () => {
        setLoading(true)
        const response = await serviceProposalService.approveServiceProposal({
            serviceProposal: serviceProposal,
            paymentOptions: paymentOptions
        })

        if (response) {
            window.location.reload()
        }
        setLoading(false)
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-redirect-service-container">
                <div className="modal-redirect-service-title-content">
                    <h2>Aprovar Proposta</h2>
                </div>
                <div>
                    <div className="new-value-content">
                        <span>Onde irá realizar o pagamento:</span>
                        <div className="new-value-button-group">
                            {paymentPlace.map((paymentPlace) => {
                                return (
                                    <button 
                                        key={paymentPlace.id}
                                        className={`new-value-button-button ${paymentOptions.paymentPlace === paymentPlace.id && "active"}`}
                                        onClick={() => handleSetPaymentOptions("paymentPlace", paymentPlace.id)}
                                    >
                                        {paymentPlace.label}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="new-value-content">
                        <span>Forma de pagamento:</span>
                        <div className="new-value-button-group">
                            {paymentMethods.map((paymentMethod) => {
                                return (
                                    <button 
                                        key={paymentMethod.id}
                                        className={`new-value-button-button ${paymentOptions.paymentMethod === paymentMethod.id && "active"}`}
                                        onClick={() => handleSetPaymentOptions("paymentMethod", paymentMethod.id)}
                                    >
                                        {paymentMethod.label}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="new-value-content">
                        <span>Deseja ajustar a taxa:</span>
                        <InputText 
                            label="Qual valor negociado com o cliente?"
                            value={serviceProposal.fee}
                            onChange={(event) => dispatch(updateFee(event.target.value))}
                            style={{
                                marginTop: 8
                            }}
                        />
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <span>Valor da proposta: <span style={{color: "#009CF5"}}>{Number(serviceProposal.priceProvider).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</span></span>
                            <span>Valor da taxa: <span style={{color: "#009CF5"}}>{Number(serviceProposal.fee).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</span></span>
                            <span>Valor Final: <span style={{color: "#009CF5"}}>{Number(serviceProposal.price).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</span></span>
                        </div>
                    </div>
                </div>
                <div className="paper-solicitation-summury-footer">
                    <Button 
                        loading={loading}
                        disabled={paymentOptions.paymentPlace === "" || paymentOptions.paymentMethod === ""}
                        label={"Aprovar Proposta"}
                        style={{width: "100%"}}
                        onClick={submmit}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ApproveServiceProposalModal
