import CustomAvatar from "../../Avatar"

import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import './styles.css'

const PaperProvider = ({
    provider
}) => {

    const servicesIcon = {
        "mecanico": MecanicoIcon,
        "bateria": BateriaIcon,
        "chaveiro": ChaveiroIcon,
        "combustivel": CombustivelIcon,
        "pneu": PneusIcon,
        "reboque": ReboqueIcon
    }

    return (
        <div className="paper-provider-container">
            <div className="paper-provider-content">
                <div className="paper-provider-info-content">
                    <CustomAvatar label={provider.name} size={32}/>
                    <div className="paper-provider-info">
                        <label>{provider.name}</label>
                        <span>{provider.email}</span>
                    </div>
                </div>
                <a 
                    className="paper-provider-icon-button"
                    href={`https://wa.me/55${provider.cellphone.replace(/[^0-9]/g, '')}`}
                />
            </div>
            <div className="paper-provider-services-content">
                <span>Serviços Ativos:</span>
                <div>
                    {provider.config.map(item => {
                        return (
                            <img src={servicesIcon[item.service]} alt={item.service}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PaperProvider