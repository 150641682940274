import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

const CustomSwitch = ({
    label,
    checked,
    onChange,
    laoding
}) => {
    return (
        <FormControlLabel
            control={
                laoding ? (
                    <CircularProgress style={{color: "#009CF5", marginLeft: 16}} size="20px"/>
                ) : (
                    <Switch 
                        checked={checked}
                        onChange={onChange}
                        style={{color: "#009CF5"}}
                    />
                )
            }
            label={label}
            labelPlacement="start"
        />
    );
}

export default CustomSwitch