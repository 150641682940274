import { useDispatch, useSelector } from "react-redux"
import CustomAvatar from "../../Avatar"

import * as chatService from '../../../services/chat'

import { selectLead } from "../../../action/leads"
import { setContact } from "../../../action/newSolicitations"

import './styles.css'

const PaperLead = ({lead, active}) => {
    const dispatch = useDispatch()
    const messages = useSelector(state => state.lead.messages)

    const handleSelectLead = () => {
        dispatch(selectLead(lead))
        dispatch(setContact({
            uuid: lead.uuid,
            cellphone: lead.cellphone
        }))
        chatService.readMessages(lead)
    }

    return (
        <button onClick={handleSelectLead} className={`paper-leads-container ${active && "active"}`}>
            <div className="paper-leads-avatar-content">
                <CustomAvatar label={lead.name ? lead.name : "Não Informado"} size={36}/>
                <div className="paper-leads-info">
                    <label>{lead.name ? lead.name : "Não Informado"}</label>
                    <span>{lead.cellphone && lead.cellphone}</span>
                </div>
            </div>
            {!active &&
                <span className="paper-leads-count-messages">
                    Mnesagens não lidas:
                    <strong>
                        {messages.filter(item => item.uuidUser === lead.uuid && !item.read).length}
                    </strong> 
                </span>
            }
        </button>
    )
}
export default PaperLead