import { useEffect, useState } from "react"
import CryptoJS from 'crypto-js';

import * as partnersService from '../../../services/partners'

import './styles.css'
import InputText from "../../Input/Text"
import Button from "../../Button"

const PaperPlan = ({partner}) => {
    const [status, setStatus] = useState()
    const [startAt, setStartAt] = useState()
    const [editCreditCard, setEditCreditCard] = useState(false)
    const [editPlanAmount, setEditPlanAmount] = useState(false)
    const [holderPersonType, setHolderPersonType] = useState("documentPJ")

    const [loadingUpdateCreditCard, setLoadingUpdateCreditCard] = useState(false)
    const [updateCreditCardForms, setUpdateCreditCardForms] = useState({
        number: "",
        expires: "",
        cvc: "",
        holder: "",
        holderDocument: ""
    })

    const [loadingNewAmount, setLoadingNewAmount] = useState(false)
    const [newAmount, setNewAmount] = useState("")

    const documentType = [
        {id: "documentPF", label: "Pessoa Física"},
        {id: "documentPJ", label: "Pessoa Jurídica"},
    ]

    const random16String = () => {
        let result = '';
        for (let i = 0; i < 16; i++) {
            result += Math.floor(Math.random() * 10);
        }
        return String(result);
    }

    const encryptedCard = (card) => {
        const derived_key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEPPER_SECRET_KEY)
        const randomInt16 = random16String()
        const iv = CryptoJS.enc.Utf8.parse(randomInt16);
        
        const cipherText = CryptoJS.AES.encrypt(JSON.stringify(card), derived_key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
        }).toString()

        return randomInt16 + cipherText
    }

    const formatDate = (date) => {
        return `${new Date(date).toLocaleDateString()}`
    }

    const formatDatetime = (dateString) => {
        const date = new Date(dateString)
        date.setHours(date.getHours() - 3)
        return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`
    }

    const handleEdit = (context) => {
        if (context === "amount") {
            if (editPlanAmount) {
                setEditPlanAmount(!editPlanAmount)
            } else {
                setEditCreditCard(false)
                setEditPlanAmount(true)
            }
        } else {
            if (editCreditCard) {
                setEditCreditCard(!editCreditCard)
            } else {
                setEditCreditCard(true)
                setEditPlanAmount(false)
            }
        }
    }

    const handleUpdateCreditCardForms = (column, value) => {
        setUpdateCreditCardForms({
            ...updateCreditCardForms,
            [column]: value
        })
    }

    const handleSubmitUpdateCreditCard = async () => {
        setLoadingUpdateCreditCard(true)

        const cardEncrypted = encryptedCard({data: updateCreditCardForms})

        const response = await partnersService.updateCreditCard({
            uuid: partner.uuid,
            subscriptionId: partner.subscriptionId,
            customerId: partner.customerId,
            addressId: partner.addressId,
            card: cardEncrypted
        })

        if (response) window.location.reload()

        setLoadingUpdateCreditCard(false)
    }

    const handleSubmitUpdateAmount = async () => {
        setLoadingNewAmount(true)

        const response = await partnersService.updateAmount({
            ...partner,
            planAmount: newAmount
        })

        if (response) window.location.reload()

        setLoadingNewAmount(false)
    }

    useEffect(() => {
        const currentDate = new Date()
        const _startAt = new Date(partner.subscriptionStartAt)
        const status = !partner.active ? "inactive" : _startAt > currentDate ? "furute" : "active"

        setStartAt(_startAt)
        setStatus(status)
    }, [partner])

    return (
        <div className="paper-plan-container">
            <span>
                <strong>Criado em: </strong>
                {formatDatetime(partner.createdAt)}
            </span>
            <span>
                <strong>{status === "furute" ? "Plano iniciará em: " : "Iniciou em: "}</strong>
                {formatDate(startAt)}
            </span>
            <span>
                <strong>Cartão: </strong>
                {`${partner.card.first_six_digits} **** ${partner.card.last_four_digits} - ${partner.card.brand}`}
                <button className="partners-dashboard-forms-link" onClick={() => handleEdit("creditCard")}>Editar</button>
            </span>
            <span>
                <strong>Valor do plano: </strong>
                {Number(partner.planAmount).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}
                <button className="partners-dashboard-forms-link" onClick={() => handleEdit("amount")}>Editar</button>
            </span>
            {editCreditCard &&
                <div style={{marginTop: 16}}>
                    <span style={{fontWeight: 'bold'}}>Editar Dados do Cartão</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        columnGap: 8,
                        marginTop: 8
                    }}>
                        <InputText 
                            mask="card"
                            label="Número do cartão" 
                            value={updateCreditCardForms.number}
                            onChange={(event) => handleUpdateCreditCardForms("number", event.target.value)}
                        />
                        <InputText 
                            mask="expcard"
                            label="Validade" 
                            value={updateCreditCardForms.expires}
                            onChange={(event) => handleUpdateCreditCardForms("expires", event.target.value)}
                        />
                        <InputText 
                            label="CVC"
                            value={updateCreditCardForms.cvc}
                            onChange={(event) => handleUpdateCreditCardForms("cvc", event.target.value)}
                        />
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: 8,
                        rowGap: 8,
                        marginTop: 8
                    }}>
                        {documentType.map((item, index) => {
                            return (
                                <button 
                                    key={index}
                                    onClick={() => setHolderPersonType(item.id)}
                                    className={`partners-dashboard-forms-button ${item.id === holderPersonType && "active"}`}
                                >
                                    {item.label}
                                </button>
                            )
                        })}
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: 8,
                        rowGap: 8,
                        marginTop: 8
                    }}>
                        <InputText 
                            label="Nome do titular do cartão"
                            value={updateCreditCardForms.holder}
                            onChange={(event) => handleUpdateCreditCardForms("holder", event.target.value)}
                        />
                        <InputText 
                            mask={holderPersonType}
                            label="Documento do titular do cartão"
                            value={updateCreditCardForms.holderDocument}
                            onChange={(event) => handleUpdateCreditCardForms("holderDocument", event.target.value)}
                        />
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8
                    }}>
                        <Button 
                            label="Editar"
                            loading={loadingUpdateCreditCard}
                            onClick={handleSubmitUpdateCreditCard}
                        />
                    </div>
                </div>
            }
            {editPlanAmount &&
                <div style={{marginTop: 16}}>
                    <span style={{fontWeight: 'bold'}}>Editar Valor do Plano</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8,
                        columnGap: 8,
                        rowGap: 8,
                    }}>
                        <InputText 
                            label="Digite o valor do plano"
                            value={newAmount}
                            onChange={(event) => setNewAmount(event.target.value)}
                        />
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8
                    }}>
                        <Button 
                            label="Editar"
                            loading={loadingNewAmount}
                            onClick={handleSubmitUpdateAmount}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default PaperPlan