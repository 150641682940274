import { useEffect, useState } from 'react'
import CryptoJS from 'crypto-js';

import * as cep from '../../../../../services/cep'
import * as partnersService from '../../../../../services/partners'

import InputText from '../../../../../components/Input/Text'
import Button from '../../../../../components/Button'

import '../styles.css'
import PaperPartnerUser from '../../../../../components/Paper/PartnerUser';
import InputSelect from '../../../../../components/Input/Select';

const PartnersDashboardForms = ({partner}) => {
    const [loading, setLoading] = useState(false)
    const [holderPersonType, setHolderPersonType] = useState("documentPJ")
    const [forms, setForms] = useState({
        name: "",
        document: "",
        address: {
            zipCode: "",
            street: "",
            district: "",
            city: "",
            state: ""
        },
        contact: {
            name: "",
            email: "",
            phone: ""
        },
        card: {
            number: "",
            expires: "",
            cvc: "",
            holder: "",
            holderDocument: ""
        },
        planAmount: ""
    })

    const [loadingAddUser, setLoadingAddUser] = useState(false)
    const [activAddNewUser, setActiveAddNewUser] = useState(false)
    const [users, setUsers] = useState([])
    const [newUser, setNewUser] = useState({
        name: "",
        account: "",
        type: ""
    })

    const regex = {
        zipCode: /^\d{2}\.\d{3}-\d{3}$/
    }

    const documentType = [
        {id: "documentPF", label: "Pessoa Física"},
        {id: "documentPJ", label: "Pessoa Jurídica"},
    ]

    const userTypes = [
        {id: "admin", label: "Admin"},
        {id: "user", label: "Normal"},
    ]

    const random16String = () => {
        let result = '';
        for (let i = 0; i < 16; i++) {
            result += Math.floor(Math.random() * 10);
        }
        return String(result);
    }

    const encryptedCard = (card) => {
        const derived_key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEPPER_SECRET_KEY)
        const randomInt16 = random16String()
        const iv = CryptoJS.enc.Utf8.parse(randomInt16);
        
        const cipherText = CryptoJS.AES.encrypt(JSON.stringify(card), derived_key, {
            iv: iv,
            mode: CryptoJS.mode.CBC
        }).toString()

        return randomInt16 + cipherText
    }

    const handleForms = (column, value) => {
        setForms({
            ...forms,
            [column]: value
        })
    }

    const handleAddressForms = (column, value) => {
        setForms({
            ...forms,
            address: {
                ...forms.address,
                [column]: value
            }
        })
    }

    const handleContactForms = (column, value) => {
        setForms({
            ...forms,
            contact: {
                ...forms.contact,
                [column]: value
            }
        })
    }

    const handleNewUser = (column, value) => {
        setNewUser({
            ...newUser,
            [column]: value
        })
    }

    const handleAddNewUser = () => {
        setLoadingAddUser(true)
        setUsers([...users, newUser])
        setNewUser({
            name: "",
            account: "",
            type: ""
        })
        setActiveAddNewUser(false)
        setLoadingAddUser(false)
    }

    const handleRemoveNewUser = (account) => {
        setUsers((prevUsers) => prevUsers.filter(user => user.account !== account));
    }

    const handleCardForms = (column, value) => {
        setForms({
            ...forms,
            card: {
                ...forms.card,
                [column]: value
            }
        })
    }

    const hanldeGetAddress = async () => {
        const zipCode = forms.address.zipCode.replace(/[^0-9]/g, '')
        
        const response = await cep.getAddress(zipCode)
        
        if (response.status === 200) {
            const address = response.data

            setForms({
                ...forms,
                address: {
                    ...forms.address,
                    state: address.uf,
                    district: address.bairro,
                    city: address.localidade,
                    street: address.logradouro
                }
            })
        }
    }

    const submit = async () => {
        setLoading(true)
        const cardEncrypted = encryptedCard({data: forms.card})
        const payload = {
            ...forms,
            card: cardEncrypted,
            users: [
                ...users,
                {
                    name: forms.contact.name,
                    account: forms.contact.email,
                    type: "root"
                }
            ]
        }

        delete payload.action

        const response = await partnersService.createPartner(payload)
        if (response) window.location.reload()
        setLoading(false)
    }

    useEffect(() => {
        setForms({...forms, ...partner})
    }, [partner])

    useEffect(() => {
        if (regex.zipCode.test(forms.address.zipCode)) {
            hanldeGetAddress()
        }
    }, [forms.address.zipCode])

    return (
        <div className="partners-dashboard-container">
            <div className='partners-dashboard-forms-content'>
                <div className='partners-dashboard-forms-item'>{/*Dados Básicos*/}
                    <span>Dados Básicos</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: 8,
                        marginTop: 8
                    }}>
                        <InputText 
                            label="Nome da Empresa"
                            value={forms.name}
                            onChange={(event) => handleForms("name", event.target.value)}
                        />
                        <InputText 
                            label="CNPJ"
                            mask="documentPJ"
                            value={forms.document}
                            onChange={(event) => handleForms("document", event.target.value)}
                        />
                    </div>
                </div>
                <div className='partners-dashboard-forms-item'>{/*Endereço*/}
                    <span>Endereço</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8,
                        columnGap: 8,
                        rowGap: 8,
                    }}>
                        <InputText 
                            label="CEP" 
                            mask="cep"
                            value={forms.address.zipCode}
                            onChange={(event) => handleAddressForms("zipCode", event.target.value)}
                            style={{width: "calc(50% - 4px)"}}
                        />
                        <InputText 
                            label="Logradouro"
                            value={forms.address.street}
                            onChange={(event) => handleAddressForms("street", event.target.value)}
                        />
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            columnGap: 8
                        }}>
                            <InputText 
                                label="Bairro"
                                value={forms.address.district}
                                onChange={(event) => handleAddressForms("district", event.target.value)}
                            />
                            <InputText 
                                label="Cidade"
                                value={forms.address.city}
                                onChange={(event) => handleAddressForms("city", event.target.value)}
                            />
                            <InputText 
                                label="UF" 
                                value={forms.address.state}
                                onChange={(event) => handleAddressForms("state", event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='partners-dashboard-forms-item'>{/*Root*/}
                    <span>Conta Principal</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        marginTop: 8,
                        columnGap: 8,
                        rowGap: 8,
                    }}>
                        <InputText 
                            label="Nome"
                            value={forms.contact.name}
                            onChange={(event) => handleContactForms("name", event.target.value)}
                        />
                        <InputText 
                            label="E-mail" 
                            value={forms.contact.email}
                            onChange={(event) => handleContactForms("email", event.target.value)}
                        />
                        <InputText 
                            label="Telefone"
                            mask="cellphone"
                            value={forms.contact.phone}
                            onChange={(event) => handleContactForms("phone", event.target.value)}
                        />
                    </div>
                </div>
                <div className='partners-dashboard-forms-item'>{/*Cartão de crédito*/}
                    <span>Dados do cartão</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8,
                        columnGap: 8,
                        rowGap: 8,
                    }}>
                        
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            columnGap: 8,
                            rowGap: 8,
                        }}>
                            <InputText 
                                mask="card"
                                label="Número do cartão" 
                                value={forms.card.number}
                                onChange={(event) => handleCardForms("number", event.target.value)}
                            />
                            <InputText 
                                mask="expcard"
                                label="Validade" 
                                value={forms.card.expires}
                                onChange={(event) => handleCardForms("expires", event.target.value)}
                            />
                            <InputText 
                                label="CVC"
                                value={forms.card.cvc}
                                onChange={(event) => handleCardForms("cvc", event.target.value)}
                            />
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: 8,
                            rowGap: 8,
                        }}>
                            {documentType.map((item, index) => {
                                return (
                                    <button 
                                        key={item.id}
                                        onClick={() => setHolderPersonType(item.id)}
                                        className={`partners-dashboard-forms-button ${item.id === holderPersonType && "active"}`}
                                    >
                                        {item.label}
                                    </button>
                                )
                            })}
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: 8,
                            rowGap: 8,
                        }}>
                            <InputText 
                                label="Nome do titular do cartão"
                                value={forms.card.holder}
                                onChange={(event) => handleCardForms("holder", event.target.value)}
                            />
                            <InputText 
                                mask={holderPersonType}
                                label="Documento do titular do cartão"
                                value={forms.card.holderDocument}
                                onChange={(event) => handleCardForms("holderDocument", event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='partners-dashboard-forms-item'>{/*Plano*/}
                    <span>Valor do plano</span>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        marginTop: 8,
                        columnGap: 8,
                        rowGap: 8,
                    }}>
                        <InputText 
                            label="Digite o valor do plano"
                            value={forms.planAmount}
                            onChange={(event) => handleForms("planAmount", event.target.value)}
                        />
                    </div>
                </div>
                <div className='partners-dashboard-forms-item'>{/*Footer*/}
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                    }}>
                        <Button 
                            label="Cadastar parceiro"
                            loading={loading}
                            onClick={submit}
                        />
                    </div>
                </div>
            </div>
            <div className='partners-dashboard-users-content'>
                <div className='partners-dashboard-users-item'>
                    <div>
                        <div className='partners-dashboard-users-header'>
                            <span>Usuários</span>
                            <button onClick={() => setActiveAddNewUser(!activAddNewUser)}>Adicionar</button>
                        </div>
                        {activAddNewUser &&
                            <div className='partners-dashboard-forms-item'>
                                <span>Adicionar Usuário</span>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    marginTop: 8,
                                    columnGap: 8,
                                    rowGap: 8,
                                }}>
                                    <InputText 
                                        label="Nome"
                                        value={newUser.name}
                                        onChange={(event) => handleNewUser("name", event.target.value)}
                                    />
                                    <InputText 
                                        label="Conta" 
                                        placeholder="usuaro.teste"
                                        value={newUser.account}
                                        onChange={(event) => handleNewUser("account", event.target.value)}
                                    />
                                    <InputSelect 
                                        label="Tipo de conta"
                                        value={newUser.type}
                                        options={userTypes}
                                        onChange={(event) => handleNewUser("type", event.target.value)}
                                    />
                                </div>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr",
                                    marginTop: 8
                                }}>
                                    <Button 
                                        label="Adicionar"
                                        loading={loadingAddUser}
                                        onClick={handleAddNewUser}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='partners-dashboard-users-grid'>
                        <PaperPartnerUser root user={forms.contact}/>
                        {users.map((user, index) => {
                            return <PaperPartnerUser key={index} user={user} onRemoveUser={handleRemoveNewUser}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PartnersDashboardForms