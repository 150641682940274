import { createSlice } from '@reduxjs/toolkit'

export const partnersSilce = createSlice({
    name: "partners",
    initialState: {
        selected: {},
        list: []
    },
    reducers: {
        selectPartner: (state, action) => {
            state.selected = action.payload
        },
        loadPartners: (state, action) => {
            state.list = action.payload
        },
        addNewUser: (state, action) => {
            state.selected.users = [...state.selected.users, action.payload]
        },
        removeUser: (state, action) => {
            state.selected.users = state.selected.users.filter(user => user.account !== action.payload)
        },
        updateUser: (state, action) => {
            const { payload } = action
            state.selected.users = state.selected.users.map(user => {
                return user.uuid === payload.uuid ? payload : user
            })
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    selectPartner,
    loadPartners,
    addNewUser,
    removeUser,
    updateUser
} = partnersSilce.actions

export default partnersSilce.reducer