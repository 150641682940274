import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './styles.css'
import MapView from '../../MapView';

const PaperMessage = ({message}) => {

    const [address, setAddress] = useState()
    const [leadingAddress, setLoadingAddress] = useState(false)

    const formatStamp = (stamp) => {
        const date = new Date(stamp)
        return `${date.toLocaleDateString("pt-BR")}  ${date.toLocaleTimeString("pt-BR", {hour: '2-digit', minute:'2-digit'})}`
    }

    const convertToMarkdown = (text) => {
        // Substitui *texto* por **texto** para funcionar no Markdown
        return text.replace(/\*(.*?)\*/g, '**$1**');
    }

    const formatAddress = (address) => {
        const street = address.filter(item => item.types.includes("route")).map(item => item.short_name)[0]
        const streetNumber = address.filter(item => item.types.includes("street_number")).map(item => item.short_name)[0]
        const district = address.filter(item => item.types.includes("sublocality_level_1")).map(item => item.short_name)[0]
        const city = address.filter(item => item.types.includes("administrative_area_level_2")).map(item => item.short_name)[0]
        const state = address.filter(item => item.types.includes("administrative_area_level_1")).map(item => item.short_name)[0]

        return [street, streetNumber, district, city, state]
    }

    const handleGeocodingReverse = async (position) => {
        setLoadingAddress(true)
        const latitude = position.latitude
        const longitude = position.longitude

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCBFaUHIxOMqWmpSBrrWaF3uJbNDPSeXKE`
        const response = await fetch(new Request(url)).then((response) => response.json())

        if (response.status === "OK") {
            const result = response.results[0]
            const addressComponents = result.address_components

            setAddress(formatAddress(addressComponents))
        }
        setLoadingAddress(false)
    }

    useEffect(() => {
        if (message.contentType === "location") {
            handleGeocodingReverse(message.message)
        }
    }, [message])

    return (
        <div className={`paper-message ${message.type} ${message.contentType}`}>
            {message.contentType === "image" ? (
                <div className='paper-message-content'>
                    <a href={message.url} target="_blank" download="whatsapp-image.png">
                        <img alt='' src={message.url}/>
                    </a>
                    <ReactMarkdown>{convertToMarkdown(message.message)}</ReactMarkdown>
                </div>
            ) : message.contentType === "video" ? (
                <div className='paper-message-content'>
                    <video controls>
                        <source src={message.url} type="video/mp4"/>
                    </video>
                    <ReactMarkdown>{convertToMarkdown(message.message)}</ReactMarkdown>
                </div>
            ) : message.contentType === "audio" ? (
                <div className='paper-message-content'>
                    <audio controls>
                        <source src={message.url} type="audio/ogg"/>
                    </audio>
                </div>
            ) : message.contentType === "location" ? (
                <div className='paper-message-content'>
                    <div className='location'>
                        <MapView 
                            defaultZoom={14}
                            origin={{ lat: message.message.latitude, lng: message.message.longitude }}
                            point={{ lat: message.message.latitude, lng: message.message.longitude }}
                            options={{
                                draggable: false, // Desabilita o arrasto do mapa
                                draggableCursor: 'not-allowed', // Define o cursor como não permitido
                                gestureHandling: 'none', // Desabilita o zoom e outros gestos
                            }}
                        />
                    </div>
                    <ReactMarkdown>
                        {leadingAddress || !address ? "Obetendo endereço..." : address.join(', ')}
                    </ReactMarkdown>
                </div>
            ) : (
                <ReactMarkdown>{convertToMarkdown(message.message)}</ReactMarkdown>
            )}
            <div className="paper-message-footer">
                <span>{`${formatStamp(message.createdAt)} ${message.edited ? " - Editada" : ""}`}</span>
                {message.status === "READ" ? <DoneAllIcon fontSize="small" style={{color:'#009CF5'}}/> : message.status === "RECEIVED" ? <DoneAllIcon fontSize="small"/> : message.status === "SENT" ? <CheckIcon fontSize="small" /> : <AccessTimeIcon fontSize="small" />}
            </div>
        </div>
    )
}

export default PaperMessage