import { configureStore } from '@reduxjs/toolkit'

import bottomSheetSlice from './action/bottomSheetControler'
import webSocketSlice from './action/webScocket'
import providersSlice from './action/providers'
import solicitationsSlice from './action/solicitations'
import companiesSlice from './action/companies'
import newSolicitationSlice from './action/newSolicitations'
import leadSlice from './action/leads'
import servicesSlice from './action/services'
import modalSlice from './action/modal'
import orderServiceSlice from './action/orderService'
import serviceProposalsSlice from './action/serviceProposals'
import partnersSilce from './action/partners'

export default configureStore({
  reducer: {
    bottomSheets: bottomSheetSlice,
    webSocket: webSocketSlice,
    providers: providersSlice,
    solicitations: solicitationsSlice,
    companies: companiesSlice,
    newSolicitation: newSolicitationSlice,
    lead: leadSlice,
    services: servicesSlice,
    modal: modalSlice,
    orderService: orderServiceSlice,
    serviceProposal: serviceProposalsSlice,
    partners: partnersSilce
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false
    }),
})