import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateProvider } from "../action/providers";
import { setWebSocketConnection, setWebSocketReady } from "../action/webScocket";
import { insertMessage, updateMessageRead, updateMessageStatus, insertLead, updateMessage, insertUserMessage } from "../action/leads";

import * as chatService from '../services/chat'

export const useWebSocket = () => {
    const dispatch = useDispatch()
    
    const pingIntervalRef = useRef(null)
    const notificationSound = useRef(null)

    useEffect(() => {
        notificationSound.current = new Audio('/sounds/notification.mp3');
        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        return () => {
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current);
            }
        }
    }, [])

    const showNotification = (title, body) => {
        if (Notification.permission === "granted") {
            new Notification(title, { body });

            notificationSound.current.play().catch(e => console.log('Error playing sound:', e))
        }
    };

    const startWebSocket = (uuidUser) => {
        const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WSS}?uuidUser=${uuidUser}&accessType=admin`)
        
        ws.onopen = async () => {
            dispatch(setWebSocketReady(true))

            // Inicia o envio do "ping" a cada 60 segundos
            pingIntervalRef.current = setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({ action: 'keepConnection' }))
                }
            }, 60000); // 60 segundos
        }

        ws.onmessage = async (event) => {
            const data = JSON.parse(event.data)

            if (data.action === "sendLocation") {
                dispatch(updateProvider(data))
            }

            if (data.action === "newLead") {
                dispatch(insertLead(data))
                showNotification("Novo Lead", "Chegou um novo lead!")

                const response = await chatService.loadMessages([data.uuid])
                if (response) dispatch(insertUserMessage({
                    uuidUser: data.uuid,
                    messages: response
                }))
            }

            if (data.action === "receivedMessage") {
                dispatch(insertMessage(data))

                if (data.type === "receive") showNotification("Nova Mensagem", "Você tem uma nova mensagem!")
            }

            if (data.action === "updateMessageStatus") { 
                dispatch(updateMessageStatus(data))
            }

            if (data.action === "editMessage") { 
                dispatch(updateMessage({
                    messageId: data.messageId,
                    newMessage: data.message,
                }))
            }

            if (data.action === "updateReadMessage") {
                dispatch(updateMessageRead(data))
            }
        }

        ws.onclose = () => {
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current); // Limpa o intervalo quando a conexão é fechada
            }
            dispatch(setWebSocketReady(false));
        }

        dispatch(setWebSocketConnection(ws))
    }

    return {
        startWebSocket,
    }
}
