import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD6_BoSmpPuKXIWl3pB3mseG2_xujHsbCo",
  authDomain: "keeper-app-374600.firebaseapp.com",
  projectId: "keeper-app-374600",
  storageBucket: "keeper-app-374600.appspot.com",
  messagingSenderId: "374922161844",
  appId: "1:374922161844:web:df9a6fb40320542f8db1e1",
  measurementId: "G-9V849C96D8"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };