import { useDispatch, useSelector } from "react-redux"

import { selectProvider } from "../../../../action/providers"

import CustomAvatar from "../../../Avatar"

import '../styles.css'

const PaperProviderDense = ({provider}) => {
    const dispatch = useDispatch()

    const providerSelected = useSelector(state => state.providers.selected)

    return (
        <button className={`paper-provider-dense-content ${provider.uuid === providerSelected.uuid ? "active" : "inactive"}`} onClick={() => dispatch(selectProvider(provider))}>
            <div className="paper-provider-info-content">
                <CustomAvatar label={provider.name} size={32}/>
                <div className="paper-provider-info">
                    <span className="paper-provider-title">{provider.name}</span>
                    <span>{provider.email}</span>
                </div>
            </div>
            <a 
                className="paper-provider-icon-button"
                href={`https://wa.me/55${provider.cellphone.replace(/[^0-9]/g, '')}`}
            />
        </button>
    )
}

export default PaperProviderDense