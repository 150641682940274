import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CircularProgress, IconButton } from "@mui/material"
import SendIcon from '@mui/icons-material/Send'

import PaperMessage from "../../../../components/Paper/Message"
import Button from "../../../../components/Button"

import * as chatService from '../../../../services/chat'

import '../styles.css'
import { openModal } from "../../../../action/modal"
import PredefinedMessagesMenu from "../../../../components/Menus/PredefinedMessages";

const Chat = () => {
    const dispatch = useDispatch()
    const messagesEndRef = useRef(null)

    const messages = useSelector(state => state.lead.messages)
    const leadSelected = useSelector(state => state.lead.selected)

    const [newMessage, setNewMessage] = useState("")
    const [loadingSendMessage, setLodingSendMessage] = useState(false)

    const scrollToBottom = (behavior = "auto") => {
        messagesEndRef.current?.scrollIntoView({ behavior })
    }

    const sendMessage = async () => {
        setLodingSendMessage(true)
        if (newMessage && newMessage !== "") {
            const response = await chatService.sendMessage({
                message: newMessage,
                lead: leadSelected
            })

            if (response) {
                setNewMessage("")
                scrollToBottom("auto")
            }
        }
        setLodingSendMessage(false)
    }

    useEffect(() => {
        scrollToBottom("auto")
    }, [leadSelected])

    useEffect(() => {
        if (Object.keys(leadSelected).length > 0 && messages.length > 0) {
            const lastMessage = messages.at(-1)
    
            if (lastMessage.uuidUser === leadSelected.uuid) scrollToBottom("auto")
        }
    }, [messages])

    return (
        <div className="leads-messages-content">
            <div className="leads-messages-header">
                <span>Lead: {leadSelected.name}</span>
                <Button 
                    kind="secondary"
                    label="Finalizar atendimento"
                    onClick={() => dispatch(openModal({
                        modal: "finishContact",
                        value: true
                    }))}
                />
            </div>
            <div className="leads-messages-body">
                {messages.filter(item => item.uuidUser === leadSelected.uuid).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((item, index) => {
                    return (
                        <PaperMessage 
                            key={index}
                            message={item}
                        />
                    )
                })}
                <div ref={messagesEndRef} />
            </div>
            <div className="leads-messages-footer">
                <PredefinedMessagesMenu 
                    lead={leadSelected}
                    onSelected={(option) => setNewMessage(option.value)}
                />
                <textarea 
                    placeholder="Digite..."
                    value={newMessage}
                    onChange={(event) => setNewMessage(event.target.value)}
                />
                <IconButton disabled={loadingSendMessage} onClick={sendMessage}>
                    {loadingSendMessage ? (<CircularProgress size={"1.5rem"}/>) : (<SendIcon style={{color: "#009CF5"}} fontSize="inherit"/>)}
                </IconButton>
            </div>
        </div>
    )
}

export default Chat