import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        redirectService: {
            active: false,
            inputFocused: false
        },
        priceCalculator: false,
        orderService: false,
        approveServiceProposal: false,
        finishContact: false
    },
    reducers: {
        openModal: (state, action) => {
            const { payload } = action

            if (payload.modal === "redirectService") {
                state.redirectService.active = payload.value
            } else {
                state[payload.modal] =  payload.value
            }
        },
        setInputFocused: (state, action) => {
            state.redirectService.inputFocused = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    openModal,
    setInputFocused
} = modalSlice.actions

export default modalSlice.reducer