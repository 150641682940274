import axios from 'axios';

const via_cep = axios.create({
    baseURL: process.env.REACT_APP_VIA_CEP
})

const getAddress = async (zip_code) => {
    return await via_cep.get(`${zip_code}/json`)
}

export {
    getAddress
}