import { useEffect, useState } from "react"
import PartnersDashboardForms from "./Forms"

import * as partnersServices from '../../../../services/partners'

import InputText from "../../../../components/Input/Text"
import PaperPlan from "../../../../components/Paper/Plan"
import Button from "../../../../components/Button"

import './styles.css'
import { useDispatch } from "react-redux"
import { addNewUser, removeUser, updateUser } from "../../../../action/partners"
import InputSelect from "../../../../components/Input/Select"
import PaperPartnerUser from "../../../../components/Paper/PartnerUser"

const ParnterDashboard = ({
    partner
}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [loadingDisablePartner, setLoadingDisableParner] = useState(false)
    const [editBasicData, setEditBasicData] = useState(false)
    const [basicData, setBasicData] = useState({
        name: "",
        document: "",
        address: {
            zipCode: "",
            street: "",
            district: "",
            city: "",
            state: ""
        }
    })

    const [loadingAddUser, setLoadingAddUser] = useState(false)
    const [activAddNewUser, setActiveAddNewUser] = useState(false)
    const [newUser, setNewUser] = useState({
        name: "",
        account: "",
        type: ""
    })

    const userTypes = [
        {id: "admin", label: "Admin"},
        {id: "user", label: "Normal"},
    ]

    const handleBasicData = (column, value) => {
        setBasicData({
            ...basicData,
            [column]: value
        })
    }

    const handleBasicDataAddress = (column, value) => {
        setBasicData({
            ...basicData,
            address: {
                ...basicData.address,
                [column]: value
            }
        })
    }

    const handleEditBasicData = async () => {
        setLoading(true)

        const response = await partnersServices.updatePartner({
            ...basicData,
            uuid: partner.uuid
        })

        if (response) window.location.reload()

        setLoading(false)
    }

    const handleDisablePartner = async () => {
        setLoadingDisableParner(true)
        const response = await partnersServices.deletePartner(partner)

        if (response) window.location.reload()
        setLoadingDisableParner(false)
    }

    const handleNewUser = (column, value) => {
        setNewUser({
            ...newUser,
            [column]: value
        })
    }

    const handleAddNewUser = async () => {
        setLoadingAddUser(true)

        const response = await partnersServices.addNewUser({
            ...newUser,
            uuidPartner: partner.uuid
        })

        if (response) {
            dispatch(addNewUser(response))
            setNewUser({
                name: "",
                account: "",
                type: ""
            })
        }

        setActiveAddNewUser(false)
        setLoadingAddUser(false)
    }

    const handleRemoveNewUser = async (account) => {
        const user = partner.users.filter(item => item.account === account)[0]
        const response = await partnersServices.deleteUser(user)
        if (response) dispatch(removeUser(account))
    }

    const handleUpdateUser = async (user) => {
        const response = await partnersServices.updateUser(user)
        if (response) dispatch(updateUser(user))
    }

    useEffect(() => {
        if (partner.action === "new") return
        
        if (Object.keys(partner).length > 0) {    
            setBasicData({
                name: partner.name,
                document: partner.document,
                address: partner.address
            })
        }

    }, [partner])

    if (partner.action === "new") return <PartnersDashboardForms partner={partner}/>
    else if (Object.keys(partner).length > 0) {
        return (
            <div className="partners-dashboard-container">
                <div className='partners-dashboard-data-content'>
                    <div className="partners-dashboard-data-item">
                        <h3>{partner.name}</h3>
                        <div className="partners-dashboard-forms-item">
                            <PaperPlan partner={partner}/>
                        </div>
                        <div className="partners-dashboard-forms-item">
                            <span>Dados Básicos</span>
                            <button className="partners-dashboard-forms-link" onClick={() => setEditBasicData(!editBasicData)}>Editar</button>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                columnGap: 8,
                                marginTop: 8
                            }}>
                                <InputText 
                                    disabled={!editBasicData}
                                    label="Nome da Empresa"
                                    value={basicData.name}
                                    onChange={(event) => handleBasicData("name", event.target.value)}
                                />
                                <InputText 
                                    label="CNPJ"
                                    mask="documentPJ"
                                    disabled={!editBasicData}
                                    value={basicData.document}
                                    onChange={(event) => handleBasicData("document", event.target.value)}
                                />
                            </div>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                columnGap: 8,
                                marginTop: 8
                            }}>
                                <InputText 
                                    label="CEP" 
                                    mask="cep"
                                    disabled={!editBasicData}
                                    value={basicData.address.zipCode}
                                    onChange={(event) => handleBasicDataAddress("zipCode", event.target.value)}
                                />
                                <InputText 
                                    label="Logradouro"
                                    disabled={!editBasicData}
                                    value={basicData.address.street}
                                    onChange={(event) => handleBasicDataAddress("street", event.target.value)}
                                />
                            </div>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                columnGap: 8,
                                marginTop: 8
                            }}>
                                <InputText 
                                    label="Bairro"
                                    disabled={!editBasicData}
                                    value={basicData.address.district}
                                    onChange={(event) => handleBasicDataAddress("district", event.target.value)}
                                />
                                <InputText 
                                    label="Cidade"
                                    disabled={!editBasicData}
                                    value={basicData.address.city}
                                    onChange={(event) => handleBasicDataAddress("city", event.target.value)}
                                />
                                <InputText 
                                    label="UF" 
                                    disabled={!editBasicData}
                                    value={basicData.address.state}
                                    onChange={(event) => handleBasicDataAddress("state", event.target.value)}
                                />
                            </div>
                            {editBasicData &&
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr",
                                    marginTop: 8
                                }}>
                                    <Button 
                                        label="Editar"
                                        loading={loading}
                                        onClick={handleEditBasicData}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <Button 
                            kind="error"
                            label="Desativar parceiro"
                            style={{width: '100%'}}
                            loading={loadingDisablePartner}
                            onClick={handleDisablePartner}
                        />
                    </div>
                </div>
                <div className='partners-dashboard-users-content'>
                    <div className='partners-dashboard-users-item'>
                        <div className='partners-dashboard-users-header'>
                            <span>Usuários</span>
                            <button onClick={() => setActiveAddNewUser(!activAddNewUser)}>Adicionar</button>
                        </div>
                        {activAddNewUser &&
                            <div className='partners-dashboard-forms-item'>
                                <span>Adicionar Usuário</span>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    marginTop: 8,
                                    columnGap: 8,
                                    rowGap: 8,
                                }}>
                                    <InputText 
                                        label="Nome"
                                        value={newUser.name}
                                        onChange={(event) => handleNewUser("name", event.target.value)}
                                    />
                                    <InputText 
                                        label="Conta" 
                                        placeholder="usuaro.teste"
                                        value={newUser.account}
                                        onChange={(event) => handleNewUser("account", event.target.value)}
                                    />
                                    <InputSelect 
                                        label="Tipo de conta"
                                        value={newUser.type}
                                        options={userTypes}
                                        onChange={(event) => handleNewUser("type", event.target.value)}
                                    />
                                </div>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr",
                                    marginTop: 8
                                }}>
                                    <Button 
                                        label="Adicionar"
                                        loading={loadingAddUser}
                                        onClick={handleAddNewUser}
                                    />
                                </div>
                            </div>
                        }
                        <div className='partners-dashboard-users-grid'>
                            <PaperPartnerUser root user={partner.users.filter(item => item.type === "root")[0]} />
                            {partner.users.filter(item => item.type !== "root").map((user, index) => {
                                return (
                                    <PaperPartnerUser 
                                        key={index} 
                                        user={user} 
                                        onUpdateUser={handleUpdateUser}
                                        onRemoveUser={handleRemoveNewUser}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ParnterDashboard