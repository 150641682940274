import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../hooks/useAuth';

import Brand from '../../assets/gif/brand.gif'

import './styles.css'

const Auth = () => {
    const { login } = useAuth()

    const handleAuth = (credentialResponse) => {
        login(credentialResponse)
    }

    return (
        <div className='auth-container'>
            <div className='auth-content'>
                <img src={Brand} alt="logo"/>
                <GoogleLogin
                    useOneTap
                    onSuccess={credentialResponse => handleAuth(credentialResponse)}
                    onError={() => console.log('Login Failed')}
                />
            </div>
        </div>
    );
}

export default Auth;