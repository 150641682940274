import { useState } from 'react';

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Button from '../../Button';

import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import * as solicitationService from '../../../services/solicitations'
import * as usersSercice from '../../../services/users'
import * as payments from '../../../services/payments'

import './styles.css'
import { useDispatch } from 'react-redux';
import { refreshProposals, refreshUser } from '../../../action/solicitations';
import { openModal } from '../../../action/modal';
import { setOrder } from '../../../action/orderService';
import { setServiceProposal } from '../../../action/serviceProposals';

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const CustomTableRow = ({ row, columns }) => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [loadingPaymentsLink, setLoadingPaymentsLink] = useState(false)
    const [loadingFinishSolicitation, setLoadingFinishSolicitation] = useState(false)
    const [loadingRestartSolicitation, setLoadingRestartsolicitation] = useState(false)
    const [loadingRefreshUserInfo, setLoadingRefreshUserInfo] = useState(false)
    const [loadingRefreshProposal, setLoadingRefreshProposal] = useState(false)
    const [loadingCancelSolicitation, setLoadingCancelSolicitation] = useState(false)
    const [loadingCopy, setLoadingCopy] = useState(false)
    const [copyFeedback, setCopyFeedback] = useState()

    const status = {
        new: "Ativa",
        active: "Ativa",
        finish: "Finalizada",
        canceled: "Cancelada",
    }

    const paymentStatus = {
        success: "Sucesso",
        pending: "Pendente",
        canceled: "Cancelado"
    }

    const difficulty = {
        simple: "Simples",
        moderate: "Moderado",
        complex: "Complexo"
    }

    const stages = {
        lookingForProvider: "Procurando prestador",
        in_progress: "Em deslocamento",
        start_service: "Iniciado",
        finish_service: "Finalizado",
        canceled: "Cancelada",
        starting: "Iniciando"
    }

    const proposalColumns = {
        uuid: "#",
        createdAt: "Criada em",
        distance: "Distância",
        price: "Valor Final",
        priceProvider: "Proposta",
        fee: "Taxa",
        service: "Serviço",
        status: "Status",
        company: "Empresa",
        provider: "Prestador",
    }

    const proposalStatus = {
        watingResponse: "Em análise",
        in_progress: "Em execução",
        executed: "Executada",
        refused: "Recusada",
        approved: "Aprovada"
    }

    const services = {
        mecanico: 'Mecânico',
        bateria: 'Carga de Bateria',
        chaveiro: 'Chaveiro',
        combustivel: 'Combustível',
        pneu: 'Troca de Pneus',
        reboque: "Reboque"
    }

    const servicesIcon = {
        mecanico: MecanicoIcon,
        bateria: BateriaIcon,
        chaveiro: ChaveiroIcon,
        combustivel: CombustivelIcon,
        pneu: PneusIcon,
        reboque: ReboqueIcon
    }

    const userType = {
        user: "Cliente",
        lead: "Lead"
    }

    const paymentMethodLabel = {
        pix: "PIX",
        money: "Dinheiro",
        credit_card: "Cartão de crédito"
    }

    const paymentPlaceLabel = {
        onsite: "No local",
        online: "Online"
    }

    const tiresConditionsLabel = {
        furado: "Furado",
        rasgado: "Rasgado",
        estourado: "Estourado"
    }

    const keysConditionsLabel = {
        inVehicle: "Dentro do veículo",
        quebrada: "Quebrada"
    }

    const proposalColumn = row.serviceProposals.length > 0 && Object.keys(row.serviceProposals[0])
    const proposalValues = row.serviceProposals.length > 0 && Object.values(row.serviceProposals)

    const restartSolicitation = async () => {
        setLoadingRestartsolicitation(true)
        const response = await solicitationService.restartSolicitation(row.uuid)
        if (response) {
            window.location.reload()
        }
        setLoadingRestartsolicitation(false)
    }

    const finishSolicitation = async () => {
        setLoadingFinishSolicitation(true)
        const response = await solicitationService.finishSolicitation(row.uuid)
        if (response) {
            window.location.reload()
        }
        setLoadingFinishSolicitation(false)
    }

    const copyLink = async (url, button) => {
        navigator.clipboard.writeText(url)
        setCopyFeedback(button)
        await sleep(300)
        setCopyFeedback()
    }

    const handleRefreshUserInfo = async (uuidSolicitation) => {
        setLoadingRefreshUserInfo(true)
        const response = await usersSercice.getUserInformation(uuidSolicitation)

        if (response) {
            dispatch(refreshUser({
                uuidSolicitation: uuidSolicitation,
                user: response
            }))
        }
        setLoadingRefreshUserInfo(false)
    }

    const handleRefreshProposal = async (uuidSolicitation) => {
        setLoadingRefreshProposal(true)
        const response = await solicitationService.getServiceProposal(uuidSolicitation)

        if (response) {
            dispatch(refreshProposals({
                uuidSolicitation: uuidSolicitation,
                proposals: response
            }))
        }
        setLoadingRefreshProposal(false)
    }

    const handleCancelSolicitation = async (solicitation) => {
        setLoadingCancelSolicitation(true)
        const response = await solicitationService.cancelSolicitation(solicitation)
        
        if (response) {
            window.location.reload()
        }

        setLoadingCancelSolicitation(false)
    }

    const handleGenerateOrderService = (order) => {
        dispatch(setOrder(order))

        dispatch(openModal({
            modal: "orderService",
            value: true
        }))
    }

    const handleGeneratePaymentLink = async (order) => {
        setLoadingPaymentsLink(true)
        const response = await payments.createPaymentLink(order.uuid)
        if (response) {
            console.log(response.paymentLink)
            navigator.clipboard.writeText(response.paymentLink)
        }
        setLoadingPaymentsLink(false)
    }

    const handleApproveServiceProposal = (serviceProposal) => {
        dispatch(setServiceProposal(serviceProposal))
        dispatch((openModal({
            modal: "approveServiceProposal",
            value: true
        })))
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {columns.map((col, index) => {
                    if (col === "status") return <TableCell key={index}>{status[row[col]]}</TableCell>
                    if (col === "stage") return <TableCell key={index}>{stages[row[col]]}</TableCell>
                    if (col === "createAt") return <TableCell key={index}>{`${new Date(row[col]).toLocaleDateString()} ${new Date(row[col]).toLocaleTimeString()}`}</TableCell>
                    if (col === "difficulty") return <TableCell key={index}>{difficulty[row[col]]}</TableCell>
                    if (col === "service") return <TableCell key={index}>{<img src={servicesIcon[row[col]]} alt={row[col]}/>}</TableCell>
                    if (col === "paymentStatus") return (
                        <TableCell key={index}>
                            {row.status === "canceled" ? "Cancelada" : !row[col] ? "Aguardando" : paymentStatus[row[col]]}
                        </TableCell>
                    )
                    return <TableCell key={index}>{row[col]}</TableCell>
                })}
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={8}>
                    <Collapse in={open} unmountOnExit>
                        <div className='table-solicitation-collapse-container'>
                            <div className='table-solicitation-info-container'>
                                <div>
                                    <span className='table-solicitation-info-title'>{`${services[row.service]} - ${row.uuid}`}</span>
                                    <div className='table-solicitation-info-content'>
                                        <span>
                                            <strong>Veículo: </strong>
                                            <label>{row.vehicle}</label>
                                        </span>
                                        <span>
                                            <strong>{row.service === "reboque" ? "Local do veículo: " : "Endereço: " }</strong>
                                            <label>{row.current_location.formattedAddress}</label>
                                        </span>
                                        {row.service === "reboque" &&
                                            <span>
                                                <strong>Destino: </strong>
                                                <label>{row.destinyLocation.formattedAddress}</label>
                                            </span>
                                        }
                                        {row.report !== "" &&
                                            <span>
                                                <strong>Relato: </strong>
                                                <label>{row.report}</label>
                                            </span>
                                        }
                                        <span>
                                            <strong>Forma de pagamento: </strong>
                                            <label>
                                                {
                                                    row.status === "canceled" ? "Cancelada" : 
                                                    !row.paymentStatus ? "Aguardando" : 
                                                    `${paymentMethodLabel[row.paymentMethod]} - ${paymentPlaceLabel[row.paymentPlace]}`
                                                }
                                            </label>
                                        </span>
                                        <span>
                                            <strong>Código: </strong>
                                            <label>{row.validateCode}</label>
                                        </span>
                                        <span>
                                            <strong>Link - Prestador: </strong>
                                            <button 
                                                className='table-button-link'
                                                onClick={() => copyLink(`http://keeper-redirect.vercel.app/home/${row.uuid}`, "provider")}
                                            >
                                                {copyFeedback === "provider" ? "Copiado!! ✅" : `http://keeper-redirect.vercel.app/home/${row.uuid}`}
                                            </button>
                                        </span>
                                        <span>
                                            <strong>Link - Empresa: </strong>
                                            <button 
                                                className='table-button-link'
                                                onClick={() => copyLink(`https://gerenciamento.keeper.tec.br/?solicitacao=${row.uuid}`, "management")}
                                            >
                                                {copyFeedback === "management" ? "Copiado!! ✅" : `https://gerenciamento.keeper.tec.br/?solicitacao=${row.uuid}`}
                                            </button>
                                        </span>
                                        <span>
                                            <strong>Link - Cliente: </strong>
                                            <button 
                                                className='table-button-link'
                                                onClick={() => copyLink(`https://sistema.keeper.tec.br/?solicitacao=${row.uuid}`, "consumer")}
                                            >
                                                {copyFeedback === "consumer" ? "Copiado!! ✅" : `https://sistema.keeper.tec.br/?solicitacao=${row.uuid}`}
                                            </button>
                                        </span>
                                    </div>
                                    {["pneu", "chaveiro"].includes(row.service) &&
                                        <div style={{marginTop: 16}}>
                                            <span className='table-solicitation-info-title'>{row.service === "pneu" ? "Condições do pneu" : "Condições da chave"}:</span>
                                            {row.service === "pneu" &&
                                                <div className='table-solicitation-info-content'>
                                                    <span>
                                                        <strong>Situação: </strong>
                                                        <label>{tiresConditionsLabel[row.tiresConfig.tire_conditions]}</label>
                                                    </span>
                                                    <span>
                                                        <strong>Possui ferramentas? </strong>
                                                        <label>{row.tiresConfig.has_tools === "sim" ? "Sim" : "Não"}</label>
                                                    </span>
                                                    <span>
                                                        <strong>Possui estepe? </strong>
                                                        <label>{row.tiresConfig.has_steppe === "sim" ? "Sim" : "Não"}</label>
                                                    </span>
                                                    {row.tiresConfig.has_steppe === "sim" &&
                                                        <span>
                                                            <strong>Estepe está em condições de uso? </strong>
                                                            <label>{row.tiresConfig.steppe_is_full === "sim" ? "Sim" : "Não"}</label>
                                                        </span>
                                                    }
                                                </div>
                                            }
                                            {row.service === "chaveiro" &&
                                                <div className='table-solicitation-info-content'>
                                                    <span>
                                                        <strong>Situação: </strong>
                                                        <label>{keysConditionsLabel[row.keyConfig.condition]}</label>
                                                    </span>
                                                    {row.keyConfig.condition !== "inVehicle" &&
                                                        <span>
                                                            <strong>Possui chave reserva? </strong>
                                                            <label>{row.keyConfig.hasExtrakey === "sim" ? "Sim" : "Não"}</label>
                                                        </span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <span className='table-solicitation-info-title'>Dados do Cliente</span>
                                        <a 
                                            target="_blank"
                                            className="table-solicitation-info-icon-button" 
                                            href={`https://wa.me/${row.cellphone.replace(/[^0-9]/g, '')}`}
                                        />
                                    </div>
                                    <div className='table-solicitation-info-content'>
                                        <span>
                                            <strong>Tipo: </strong>
                                            <label>{userType[row.user.type]}</label>
                                        </span>
                                        <span>
                                            <strong>Nome: </strong>
                                            <label>{row.user.name}</label>
                                        </span>
                                        {row.user.type === "user" &&
                                            <>
                                                <span>
                                                    <strong>Email: </strong>
                                                    <label>{row.user.email}</label>
                                                </span>
                                            </>
                                        }
                                        {row.user.type !== "user" &&
                                            <Button 
                                                kind="secondary"
                                                label="Atualizar dados"
                                                loading={loadingRefreshUserInfo} 
                                                onClick={() => handleRefreshUserInfo(row.uuid)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='table-solicitation-proposal-content'>
                                <div>
                                    <span className='table-solicitation-info-title'>{`Propostas Envidas (${proposalValues.length > 0 ? proposalValues.length : 0})`}</span>
                                    <Button 
                                        kind="secondary"
                                        label="Atualizar dados"
                                        loading={loadingRefreshProposal} 
                                        onClick={() => handleRefreshProposal(row.uuid)}
                                        style={{
                                            marginLeft: 8
                                        }}
                                    />
                                </div>
                                {proposalValues.length > 0 && (
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <Table size="small" aria-label="serviceProposal">
                                            <TableHead>
                                                {proposalColumn.map((column, index) => {
                                                    if (!["uuidProvider", "uuidCompany", "uuidSolicitation"].includes(column)) return <TableCell key={index}><strong>{proposalColumns[column]}</strong></TableCell>
                                                })}
                                                <TableCell />
                                            </TableHead>
                                            <TableBody>
                                                {proposalValues.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            {proposalColumn.map((col, index) => {
                                                                if (col === "status") return <TableCell key={index}>{proposalStatus[row[col]]}</TableCell>
                                                                if (col === "distance") return <TableCell key={index}>{`${Number(row[col]).toFixed(2)} km`}</TableCell>
                                                                if (["price", "fee", "priceProvider"].includes(col)) return <TableCell key={index}>{Number(row[col]).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</TableCell>
                                                                if (col === "createdAt") return <TableCell key={index}>{`${new Date(row[col]).toLocaleDateString()} ${new Date(row[col]).toLocaleTimeString()}`}</TableCell>
                                                                if (!["uuidProvider", "uuidCompany", "uuidSolicitation"].includes(col)) return <TableCell key={index}>{row[col]}</TableCell>
                                                            })}
                                                            <TableCell>
                                                                {row.status === "watingResponse" &&
                                                                    <Button 
                                                                        label="Aprovar"
                                                                        onClick={() => handleApproveServiceProposal(row)}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                )}
                            </div>
                        </div>
                        {["canceled", "active", "new", "finish"].includes(row.status) &&
                            <>
                                <div className='table-solicitation-hr'/>
                                <div className='table-solicitation-footer'>
                                    {row.status !== "canceled" &&
                                        <Button 
                                            loading={loadingPaymentsLink}
                                            label="Gerar link de pagamento"
                                            onClick={() => handleGeneratePaymentLink(row)}
                                            style={{marginRight: 8}}
                                        />
                                    }
                                    {row.status === "finish" &&
                                        <Button 
                                            label="Gerar ordem de serviço"
                                            onClick={() => handleGenerateOrderService(row)}
                                            style={{marginRight: 8}}
                                        />
                                    }
                                    {row.status !== "finish" &&
                                        <>
                                            <Button 
                                                label="Reabrir Solicitação"
                                                loading={loadingRestartSolicitation} 
                                                onClick={restartSolicitation}
                                                style={{marginRight: 8}}
                                            />
                                            <Button 
                                                label="Finalizar Solicitação"
                                                loading={loadingFinishSolicitation} 
                                                onClick={finishSolicitation}
                                                style={{marginRight: 8}}
                                            />
                                            {["active", "new"].includes(row.status) && 
                                                <Button 
                                                    kind="error"
                                                    label="Cancelar solicitação"
                                                    loading={loadingCancelSolicitation}
                                                    onClick={() => handleCancelSolicitation(row)}
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default CustomTableRow