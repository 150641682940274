import api from "./api"

export const loadMessages = async (users) => {
    try {
        const response = await api.post('/whatsApp/loadMessages', users)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("loadMessages", error)
    }
}

export const sendMessage = async (message) => {
    try {
        const response = await api.post('/whatsApp/send', message)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("sendMessage", error)
    }
}

export const readMessages = async (lead) => {
    try {
        const response = await api.post('/whatsApp/read', lead)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("readMessages", error)
    }
}