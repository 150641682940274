import { Routes, Route } from "react-router-dom"
import { ProtectedRoute } from './Protected'

import Auth from '../pages/Auth'
import Mapping from '../pages/App/Mapping'
import Providers from "../pages/App/Providers"
import Solicitations from '../pages/App/Solicitations'
import Leads from "../pages/App/Leads"
import usePushNotifications from "../hooks/usePushNotifications"
import Partners from "../pages/App/Partners"

const Router = () => {
    const { startNotificationService } = usePushNotifications()

    startNotificationService()

    return (
        <Routes>
            <Route path="/" element={<Auth />}/>
            <Route path="/mapeamento" element={<ProtectedRoute><Mapping /></ProtectedRoute>}/>
            <Route path="/solicitacoes" element={<ProtectedRoute><Solicitations /></ProtectedRoute>}/>
            <Route path="/prestadores" element={<ProtectedRoute><Providers /></ProtectedRoute>}/>
            {/*<Route path="/nova-solicitacao" element={<ProtectedRoute><NewSolicitation /></ProtectedRoute>}/>*/}
            <Route path="/leads" element={<ProtectedRoute><Leads /></ProtectedRoute>}/>
            <Route path="/parceiros" element={<ProtectedRoute><Partners /></ProtectedRoute>}/>
        </Routes>
    )
}

export default Router;