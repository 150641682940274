import { useState } from 'react'
import { messaging } from '../services/firebase'
import { onMessage, getToken } from 'firebase/messaging'

const usePushNotifications = () => {
    const [token, setToken] = useState(null)
    const [permission, setPermission] = useState(Notification.permission)
    const [message, setMessage] = useState(null)

    const registerServiceWorker = async () => {
        try {
            await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                scope: '/firebase-cloud-messaging-push-scope'
            })

            requestPermission()
        } catch (err) {
            console.error('Erro ao registrar o Service Worker:', err)
        }
    }

    const requestPermission = async () => {
        try {
            const permission = await Notification.requestPermission()
            setPermission(permission)

            if (permission === 'granted') {
                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPI })
                if (currentToken) {
                    console.log("currentToken:", currentToken)
                    setToken(currentToken)
                }
            }
        } catch (err) {
            console.error('Erro ao solicitar permissão:', err)
        }
    };

    const startNotificationService = async () => {
        registerServiceWorker()

        onMessage(messaging, (payload) => {
            console.log('Mensagem recebida em primeiro plano:', payload);
            setMessage(payload.notification)
        })
    }

    return { token, permission, message, startNotificationService };
};

export default usePushNotifications;
