import { IconButton } from "@mui/material";
import Avatar from "../../Avatar";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import './styles.css'
import { useState } from "react";
import Switch from "../../Input/Switch";

const PaperPartnerUser = ({
    root,
    user,
    onUpdateUser,
    onRemoveUser
}) => {
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)

    const handleOnRemoveUser = async () => {
        setLoadingDelete(true)
        await onRemoveUser(user.account)
        setLoadingDelete(false)
    }

    const handleOnUpdateUser = async (isAdmin) => {
        setLoadingUpdate(true)
        await onUpdateUser({
            ...user,
            type: isAdmin ? "admin" : "normal"
        })
        setLoadingUpdate(false)
    }

    if (root) {
        return (
            <div className="paper-partner-user-container root">
                <div className="paper-partner-user-data-content">
                    <Avatar label={user.name}/>
                    <div className="paper-partner-user-data">
                        <label>
                            {user.name === "" ? "Não informado": user.name}
                        </label>
                        <span>
                            {user.email && user.email !== "" ? user.email : user.account && user.account !== "" ? user.account : "Não informado"}
                        </span>
                    </div>
                </div>
                <span className="paper-partner-user-type">Conta Principal</span>
            </div>
        )
    }
    return (
        <div className="paper-partner-user-container">
            <div className="paper-partner-user-data-content">
                <Avatar label={user.name}/>
                <div className="paper-partner-user-data">
                    <label>{user.name}</label>
                    <span>{user.account}</span>
                </div>
            </div>
            <Switch 
                label="Admin?"
                laoding={loadingUpdate}
                onChange={(event) => handleOnUpdateUser(event.target.checked)}
                checked={user.type === "admin"}
            />
            <div className="paper-partner-user-type-content">
                <span className="paper-partner-user-type">
                    {user.type === "admin" ? "Admin" : "Normal"}
                </span>
                <IconButton disabled={loadingDelete} style={{marginLeft: 24}} onClick={handleOnRemoveUser}>
                    {loadingDelete ? 
                        <CircularProgress style={{color: "#009CF5"}} size="20px"/> :
                        <DeleteIcon style={{color: "#009CF5"}}/>
                    }
                </IconButton>
            </div>
        </div>
    )
}

export default PaperPartnerUser;