import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as partnersService from '../../../services/partners'

import { loadPartners } from "../../../action/partners"

import Layout from "../../../components/Layout"
import PaperPartner from "../../../components/Paper/Partner"
import ParnterDashboard from "./Dashboard"

import './styles.css'

const Partners = () => {
    const dispatch = useDispatch()
    const partnerSelected = useSelector(state => state.partners.selected)
    const partners = useSelector(state => state.partners.list)

    useEffect(() => {
        (async () => {
            const response = await partnersService.getAllPartners()
            if (response) dispatch(loadPartners(response))
        })()
    }, [])

    return (
        <Layout>
            <div className="partners-container">
                <div className="partners-list-container">
                    <div className="partners-list-content">
                        <span>Parceiros Ativos:</span>
                        <div className="partners-list-grid">
                            <PaperPartner add active={partnerSelected.action === "new"}/>
                            {partners.filter(item => item.active).map((partner, index) => {
                                return (
                                    <PaperPartner 
                                        key={index} 
                                        partner={partner} 
                                        active={partnerSelected.uuid === partner.uuid}
                                    />
                                )
                            })}
                        </div>
                        <span style={{marginTop: 16}}>Parceiros Inativos:</span>
                        <div className="partners-list-grid">
                            {partners.filter(item => !item.active).map((partner, index) => {
                                return (
                                    <PaperPartner 
                                        disabled
                                        key={index} 
                                        partner={partner} 
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="partners-data-container">
                    <ParnterDashboard 
                        partner={partnerSelected}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Partners