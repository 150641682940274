import api from '../services/api';

export const approveServiceProposal = async (payload) => {
    try {
        const response = await api.post('/serviceProposals/approve', payload)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("approveServiceProposal", error)
    }
}