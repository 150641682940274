import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import * as payments from '../../../../../services/payments'
import * as solicitationService from '../../../../../services/solicitations'

import { refreshProposals, setActiveSolcitation } from "../../../../../action/solicitations"
import { setOrder } from '../../../../../action/orderService';

import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { setServiceProposal } from "../../../../../action/serviceProposals"
import { openModal } from "../../../../../action/modal"

import Button from "../../../../../components/Button"

import './styles.css'

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const ActiveSolicitation = () => {
    const dispatch = useDispatch()

    const lead = useSelector(state => state.lead.selected)
    const solicitation = useSelector(state => state.solicitations.active)

    const [copyFeedback, setCopyFeedback] = useState()
    const [proposalColumns, setProposalColumns] = useState([])
    const [proposalValues, setProposalValues] = useState([])

    const [refreshing, setRefreshing] = useState(false)
    const [loadingPaymentsLink, setLoadingPaymentsLink] = useState(false)
    const [loadingRefreshProposal, setLoadingRefreshProposal] = useState(false)
    const [loadingCancelSolicitation, setLoadingCancelSolicitation] = useState(false)
    const [loadingFinishSolicitation, setLoadingFinishSolicitation] = useState(false)

    const tiresConditionsLabel = {
        furado: "Furado",
        rasgado: "Rasgado",
        estourado: "Estourado"
    }

    const keysConditionsLabel = {
        inVehicle: "Dentro do veículo",
        quebrada: "Quebrada"
    }

    const proposalColumnsLabel = {
        uuid: "#",
        createdAt: "Criada em",
        distance: "Distância",
        price: "Valor Final",
        priceProvider: "Proposta",
        fee: "Taxa",
        service: "Serviço",
        status: "Status",
        company: "Empresa",
        provider: "Prestador",
    }

    const proposalStatus = {
        watingResponse: "Em análise",
        in_progress: "Em execução",
        executed: "Executada",
        refused: "Recusada",
        approved: "Aprovada"
    }

    const services = {
        mecanico: 'Mecânico',
        bateria: 'Carga de Bateria',
        chaveiro: 'Chaveiro',
        combustivel: 'Combustível',
        pneu: 'Troca de Pneus',
        reboque: "Reboque"
    }

    const stages = {
        lookingForProvider: "Procurando prestador",
        in_progress: "Em deslocamento",
        start_service: "Iniciado",
        finish_service: "Finalizado",
        canceled: "Cancelada",
        starting: "Iniciando"
    }

    const copyLink = async (url, button) => {
        navigator.clipboard.writeText(url)
        setCopyFeedback(button)
        await sleep(300)
        setCopyFeedback()
    }

    const finishSolicitation = async () => {
        setLoadingFinishSolicitation(true)
        const response = await solicitationService.finishSolicitation(solicitation.uuid)
        if (response) {
            window.location.reload()
        }
        setLoadingFinishSolicitation(false)
    }

    const getActiveSolicitationByLead = async () => {
        const response = await solicitationService.getActiveSolicitationByLead({
            uuidLead: lead.uuid
        })

        if (response) dispatch(setActiveSolcitation(response.solicitation))
    }

    const handleApproveServiceProposal = (serviceProposal) => {
        dispatch(setServiceProposal(serviceProposal))
        dispatch((openModal({
            modal: "approveServiceProposal",
            value: true
        })))
    }

    const handleRefreshData = async () => {
        setRefreshing(true)
        await getActiveSolicitationByLead()
        setRefreshing(false)
    }

    const handleRefreshProposal = async () => {
        setLoadingRefreshProposal(true)
        const response = await solicitationService.getServiceProposal(solicitation.uuid)

        if (response) {
            dispatch(refreshProposals({
                uuidSolicitation: solicitation.uuid,
                proposals: response
            }))
        }
        setLoadingRefreshProposal(false)
    }

    const handleGeneratePaymentLink = async (order) => {
        setLoadingPaymentsLink(true)
        const response = await payments.createPaymentLink(order.uuid)
        if (response) {
            navigator.clipboard.writeText(response.paymentLink)
        }
        setLoadingPaymentsLink(false)
    }

    const handleGenerateOrderService = (order) => {
        dispatch(setOrder(order))

        dispatch(openModal({
            modal: "orderService",
            value: true
        }))
    }

    const handleCancelSolicitation = async (solicitation) => {
        setLoadingCancelSolicitation(true)
        const response = await solicitationService.cancelSolicitation(solicitation)
        
        if (response) {
            window.location.reload()
        }

        setLoadingCancelSolicitation(false)
    }

    useEffect(() => {
        const columns = solicitation.serviceProposals.length > 0 && Object.keys(solicitation.serviceProposals[0])
        const values = solicitation.serviceProposals.length > 0 && Object.values(solicitation.serviceProposals)

        setProposalColumns(columns)
        setProposalValues(values)
    }, [solicitation])

    return (
        <div className="leads-solicitation-active-container">
            <div className='leads-solicitation-active-hr'/>
            <div className="leads-solicitation-active-content">
                <span className='table-solicitation-active-title'>
                    {`${services[solicitation.service]} - ${stages[solicitation.stage]}`}
                    <button disabled={refreshing} onClick={handleRefreshData} style={{marginLeft: 16}}>
                        {refreshing ? "Atualizando..." : "Atualizar dados"}
                    </button>
                </span>
                <span>
                    <strong>Veículo: </strong>
                    <label>{solicitation.vehicle}</label>
                </span>
                <span>
                    <strong>
                        {solicitation.service === "reboque" ? "Local do veículo: " : "Endereço: " }
                    </strong>
                    <label>{solicitation.current_location.formattedAddress}</label>
                </span>
                {solicitation.service === "reboque" &&
                    <span>
                        <strong>Destino: </strong>
                        <label>{solicitation.destinyLocation.formattedAddress}</label>
                    </span>
                }
                {solicitation.report !== "" &&
                    <span>
                        <strong>Relato: </strong>
                        <label>{solicitation.report}</label>
                    </span>
                }
                <span>
                    <strong>Código: </strong>
                    <label>{solicitation.validateCode}</label>
                </span>
                <div className="leads-solicitation-active-links-content" style={{marginTop: 8}}>
                    <span>
                        <strong>Link - Prestador: </strong>
                        <button
                            onClick={() => copyLink(`http://keeper-redirect.vercel.app/home/${solicitation.uuid}`, "provider")}
                        >
                            {copyFeedback === "provider" ? "Copiado!! ✅" : `Copiar`}
                        </button>
                    </span>
                    <span>
                        <strong>Link - Empresa: </strong>
                        <button
                            onClick={() => copyLink(`https://gerenciamento.keeper.tec.br/?solicitacao=${solicitation.uuid}`, "management")}
                        >
                            {copyFeedback === "management" ? "Copiado!! ✅" : `Copiar`}
                        </button>
                    </span>
                    <span>
                        <strong>Link - Cliente: </strong>
                        <button
                            onClick={() => copyLink(`https://sistema.keeper.tec.br/?solicitacao=${solicitation.uuid}`, "consumer")}
                        >
                            {copyFeedback === "consumer" ? "Copiado!! ✅" : `Copiar`}
                        </button>
                    </span>
                </div>
            </div>
            {["pneu", "chaveiro", "reboque"].includes(solicitation.service) &&
                <div className="leads-solicitation-active-describe-content">
                    <span className="table-solicitation-active-title">{solicitation.service === "pneu" ? "Condições do pneu" : solicitation.service === "reboque" ? "Condições da roda" : "Condições da chave"}:</span>
                    {solicitation.service === "pneu" &&
                        <div className="leads-solicitation-active-describe-item">
                            <span>
                                <strong>Situação: </strong>
                                <label>{tiresConditionsLabel[solicitation.tiresConfig.tire_conditions]}</label>
                            </span>
                            <span>
                                <strong>Possui ferramentas? </strong>
                                <label>{solicitation.tiresConfig.has_tools === "sim" ? "Sim" : "Não"}</label>
                            </span>
                            <span>
                                <strong>Possui estepe? </strong>
                                <label>{solicitation.tiresConfig.has_steppe === "sim" ? "Sim" : "Não"}</label>
                            </span>
                            {solicitation.tiresConfig.has_steppe === "sim" &&
                                <span>
                                    <strong>Estepe está em condições de uso? </strong>
                                    <label>{solicitation.tiresConfig.steppe_is_full === "sim" ? "Sim" : "Não"}</label>
                                </span>
                            }
                        </div>
                    }
                    {solicitation.service === "chaveiro" &&
                        <div className="leads-solicitation-active-describe-item">
                            <span>
                                <strong>Situação: </strong>
                                <label>{keysConditionsLabel[solicitation.keyConfig.condition]}</label>
                            </span>
                            {solicitation.keyConfig.condition !== "inVehicle" &&
                                <span>
                                    <strong>Possui chave reserva? </strong>
                                    <label>{solicitation.keyConfig.hasExtrakey === "sim" ? "Sim" : "Não"}</label>
                                </span>
                            }
                        </div>
                    }
                    {solicitation.service === "reboque" &&
                        <div className="leads-solicitation-active-describe-item">
                            <span>
                                <strong>Situação: </strong>
                                <label>{solicitation.wheelsConfig.condition === "freeWheels" ? "Rodas estão liberadas" : "Rodas estão travadas"}</label>
                            </span>
                        </div>
                    }
                </div>
            }
            <div style={{marginTop: 16}}>
                <span className='table-solicitation-active-title'>
                    {`Propostas Envidas (${proposalValues.length > 0 ? proposalValues.length : 0})`}
                    <button disabled={loadingRefreshProposal} onClick={handleRefreshProposal} style={{marginLeft: 16}}>
                        {loadingRefreshProposal ? "Atualizando..." : "Atualizar propostas"}
                    </button>
                </span>
                {proposalValues.length > 0 &&
                    <Paper className="table-service-proposal-container">
                        <Table size="small" aria-label="serviceProposal">
                            <TableHead>
                                {proposalColumns.map((column, index) => {
                                    if (!["uuidProvider", "uuidCompany", "uuidSolicitation"].includes(column)) return <TableCell key={index}><strong>{proposalColumnsLabel[column]}</strong></TableCell>
                                })}
                                <TableCell />
                            </TableHead>
                            <TableBody>
                                {proposalValues.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            {proposalColumns.map((col, index) => {
                                                if (col === "status") return <TableCell key={index}>{proposalStatus[row[col]]}</TableCell>
                                                if (col === "distance") return <TableCell key={index}>{`${Number(row[col]).toFixed(2)} km`}</TableCell>
                                                if (["price", "fee", "priceProvider"].includes(col)) return <TableCell key={index}>{Number(row[col]).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</TableCell>
                                                if (col === "createdAt") return <TableCell key={index}>{`${new Date(row[col]).toLocaleDateString()} ${new Date(row[col]).toLocaleTimeString()}`}</TableCell>
                                                if (!["uuidProvider", "uuidCompany", "uuidSolicitation"].includes(col)) return <TableCell key={index}>{row[col]}</TableCell>
                                            })}
                                            <TableCell>
                                                {row.status === "watingResponse" &&
                                                    <Button 
                                                        label="Aprovar"
                                                        onClick={() => handleApproveServiceProposal(row)}
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                }
                <div className='table-solicitation-active-footer'>
                    {solicitation.status !== "canceled" &&
                        <Button 
                            loading={loadingPaymentsLink}
                            label="Gerar link"
                            onClick={() => handleGeneratePaymentLink(solicitation)}
                            style={{marginRight: 8}}
                        />
                    }
                    {solicitation.status !== "finish" &&
                        <>
                            <Button 
                                label="Finalizar"
                                loading={loadingFinishSolicitation} 
                                onClick={finishSolicitation}
                                style={{marginRight: 8}}
                            />
                            {["active", "new"].includes(solicitation.status) && 
                                <Button 
                                    kind="error"
                                    label="Cancelar"
                                    loading={loadingCancelSolicitation}
                                    onClick={() => handleCancelSolicitation(solicitation)}
                                />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
export default ActiveSolicitation