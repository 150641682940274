import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as vehiclesService from '../../../../../services/vehicles';
import * as solicitationService from '../../../../../services/solicitations';

import { changeCurrentLocation, changeDestinyLocation, changeKeyConfig, changeSolicitation, changeTiresConfig, changeVehicle, changeWheelsConditions } from "../../../../../action/newSolicitations";

import Button from "../../../../../components/Button"
import InputText from "../../../../../components/Input/Text"
import InputSelect from "../../../../../components/Input/Select"

import MecanicoIcon from '../../../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../../../assets/icon/small-combustivel.png'
import ReboqueIcon from '../../../../../assets/icon/small-reboque.png'
import PneusIcon from '../../../../../assets/icon/small-pneus.png'

import '../styles.css'

const NewSolicitation = () => {
    const dispatch = useDispatch()

    const lead = useSelector(state => state.lead.selected)
    const solicitation = useSelector(state => state.newSolicitation)
    const servicesState = useSelector(state => state.services.auto)

    const [locationError, setLocationError] = useState(false)
    const [notInformPlate, setNotInformePlate] = useState(false)
    const [destinyLocationError, setDestinyLocationError] = useState(false)
    const [loadingVehicleInformation, setLoadingVehicleInformation] = useState(false)
    const [loadginCreateSolicitation, setLoadingCreateSolicitation] = useState(false)
    const [formsError, setErro] = useState({
        name: {error: false, message: "Nome deve conter pelo menos 4 caracteres."},
        placa: {error: false, message: "Placa inválida"},
        address: {error: false, message: "Endereço inválido"},
        destiny: {error: false, message: "Endereço inválido"},
        email: {error: false, message: "E-mail inválido"},
        vehicle: {error: false, message: "Veículo inválido"},
    })

    const services = [
        {id: "bateria", label: "Socorro Bateria", icon: BateriaIcon},
        {id: "pneu", label: "Troca de Pneus", icon: PneusIcon},
        {id: "reboque", label: "Reboque", icon: ReboqueIcon},
        {id: "chaveiro", label: "Chaveiro", icon: ChaveiroIcon},
        {id: "mecanico", label: "Mecânico", icon: MecanicoIcon},
        {id: "combustivel", label: "Combustível", icon: CombustivelIcon},
    ]

    const vehicleType = [
        {label: "Moto", id: "Moto"},
        {label: "Carro", id: "Auto"}
    ]

    const tireConditionsOptions = [
        {id: "furado", label: "Furado"},
        {id: "rasgado", label: "Rasgado"},
        {id: "estourado", label: "Estourado"}
    ]

    const hasSteppeOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    const keyConditionsOptions = [
        {id: "quebrada", label: "Quebrada"},
        {id: "inVehicle", label: "Dentro do veículo"}
    ]

    const hasExtraKeyOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    const wheelsConditionsOptions = [
        {id: "lockedWheels", label: "As rodas estão travadas"},
        {id: "freeWheels", label: "As rodas estão livres"}
    ]

    const regex = {
        name: /^[a-zA-Z\u00C0-\u017F´]{4,}(?: [a-zA-Z\u00C0-\u017F´]+){0,}$/,
        placa: /^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$/i,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    }

    const formatAddress = (address) => {
        const street = address.filter(item => item.types.includes("route")).map(item => item.short_name)[0]
        const streetNumber = address.filter(item => item.types.includes("street_number")).map(item => item.short_name)[0]
        const district = address.filter(item => item.types.includes("sublocality_level_1")).map(item => item.short_name)[0]
        const city = address.filter(item => item.types.includes("administrative_area_level_2")).map(item => item.short_name)[0]
        const state = address.filter(item => item.types.includes("administrative_area_level_1")).map(item => item.short_name)[0]

        return [street, streetNumber, district, city, state]
    }

    const formatVehicle = (vehicle) => {
        return `${vehicle.brand} - ${vehicle.model} ${vehicle.year}/${vehicle.yearModel} - ${vehicle.color}`
    }

    const handleGetLocation = async (location) => {
        const currentLocation = location === "current_location" ? solicitation.current_location.formattedAddress : solicitation.destinyLocation.formattedAddress
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${currentLocation}&key=AIzaSyCBFaUHIxOMqWmpSBrrWaF3uJbNDPSeXKE`

        const response = await fetch(new Request(url)).then((response) => response.json())

        if (response.status === "OK") {
            setLocationError(false)
            const result = response.results[0]

            const addressComponents = result.address_components
            const geometry = result.geometry

            const address = formatAddress(addressComponents)
            dispatch(changeSolicitation({
                column: location, 
                value: {
                    street: address[0],
                    streetNumber: address[1],
                    district: address[2],
                    city: address[3],
                    state: address[4],
                    latitude: Number(geometry.location.lat),
                    longitude: Number(geometry.location.lng),
                    formattedAddress: result.formatted_address
                }
            }))
        } else {
            if (location === "current_location") setLocationError(true)
            else setDestinyLocationError(true)
        }
    }

    const handleConsultVehicle = async () => {
        setLoadingVehicleInformation(true)
        setErro({ ...formsError, placa: { ...formsError.placa, error: false}})

        if (regex.placa.test(solicitation.placa)) {
            const response = await vehiclesService.consultVehicle(solicitation.placa)
            if (response) {
                dispatch(changeVehicle({
                    service: solicitation.service,
                    vehicle: formatVehicle(response),
                    vehicleCategory: response.category,
                    vehicleOrigin: response.origin,
                    vehicleType: response.type
                }))
            } else {
                setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
            }
        } else {
            setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
        }
        setLoadingVehicleInformation(false)
    }

    const createSolicitation = async () => {
        setLoadingCreateSolicitation(true)
        const serviceSelected = services.filter(item => item.id === solicitation.service)[0].label
        const serviceAux = servicesState.filter(item => serviceSelected === item.name)[0]

        if (serviceAux.difficulty.includes(solicitation.difficulty)) {
            

            const response = await solicitationService.setSolicitation({solicitation: solicitation, leadName: lead.name})
            if (response.status === 200) {
                window.location.href = '/solicitacoes'
            }
        }
        setLoadingCreateSolicitation(false)
    }

    useEffect(() => {
        if (regex.placa.test(solicitation.placa)) {
            handleConsultVehicle()
        }
    }, [solicitation.placa])

    return (
        <div className="leads-solicitation-content">
            <div className='leads-solicitation-forms-item'>
                <span>Selecione um tipo de serviço:</span>
                <div className='leads-solicitation-button-grid'>
                    {servicesState.length > 0 && services.map((service) => {
                        return (
                            <button 
                                key={service.id}
                                className={`new-solicitation-type-services-button ${solicitation.service === service.id ? "active" : "inactive"}`}
                                onClick={() => dispatch(changeSolicitation({
                                    column: "service",
                                    value: service.id
                                }))}
                            >
                                <img alt='' src={service.icon}/>
                            </button>
                        )
                    })}
                </div>
            </div>
            <div className='leads-solicitation-forms-item'>
                <span>Qual a localização atual do veículo?</span>
                <InputText 
                    value={solicitation.current_location.formattedAddress}
                    label={"Digite a localização atual do veículo"}
                    error={locationError}
                    helperText={formsError.address.message}
                    onBlur={() => handleGetLocation("current_location")}
                    onChange={(event) => dispatch(changeCurrentLocation({
                        column:"formattedAddress", 
                        value: event.target.value
                    }))}
                    style={{marginTop: 8}}
                />
            </div>
            {solicitation.service === "reboque" && 
                <div className='leads-solicitation-forms-item'>
                    <span>Qual destino do veículo?</span>
                    <InputText 
                        value={solicitation.destinyLocation.formattedAddress}
                        label={"Digite o destino do veículo"}
                        error={destinyLocationError}
                        helperText={formsError.destiny.message}
                        onBlur={() => handleGetLocation("destinyLocation")}
                        onChange={(event) => dispatch(changeDestinyLocation({
                            column:"formattedAddress", 
                            value: event.target.value
                        }))}
                        style={{marginTop: 8}}
                    />
                </div>
            }
            <div className='leads-solicitation-forms-item'>
                <div className='leads-solicitation-forms-title-group'>
                    <span>Qual o modelo do veículo?</span>
                    <button onClick={() => setNotInformePlate(!notInformPlate)}>
                        {notInformPlate ? "Informar apenas a placa" : "Informar modelo, ano e cor do veículo manualmente"}
                    </button>
                </div>
                {notInformPlate &&
                    <div className='leads-solicitation-forms-button-group'>
                        {vehicleType.map((item, index) => {
                            return (
                                <button
                                    key={item.id}
                                    className={`new-solicitation-type-services-button ${item.id === solicitation.vehicleType ? "active" : "inactive"}`}
                                    onClick={() => dispatch(changeVehicle({
                                        service: solicitation.service,
                                        vehicle: solicitation.vehicle,
                                        vehicleCategory: solicitation.vehicleCategory,
                                        vehicleOrigin: solicitation.vehicleOrigin,
                                        vehicleType: item.id
                                    }))}
                                >
                                    {item.label}
                                </button>
                            )
                        })}
                    </div>
                }
                <InputText 
                    maxLength={!notInformPlate && 7}
                    value={notInformPlate ? solicitation.vehicle : solicitation.placa}
                    placeholder={!notInformPlate && "AAA0X00 ou AAA9999"}
                    label={notInformPlate ? "Digite o modelo, ano e a cor do veículo" : "Digite a placa do seu veículo"}
                    error={formsError.vehicle.error}
                    helperText={formsError.vehicle.message}
                    onChange={(event) => dispatch(changeSolicitation({
                        column: notInformPlate ? "vehicle" : "placa", 
                        value: String(event.target.value).toLocaleUpperCase()
                    }))}
                    style={{marginTop: notInformPlate ? 16 : 8}}
                />
                {loadingVehicleInformation &&
                    <span className='leads-solicitation-forms-vehicle'>Carregando dados do veículo...</span>
                }
                {loadingVehicleInformation || solicitation.vehicle !== "" && !notInformPlate &&
                    <span className='leads-solicitation-forms-vehicle'>
                        {loadingVehicleInformation ? (
                            "Carregando dados do veículo..."
                        ) : (
                            solicitation.vehicle
                        )}
                    </span>
                }
            </div>
            {solicitation.service === "pneu" && 
                <div className='leads-solicitation-forms-item'>
                    <span>Como está a situação do pneu?</span>
                    <InputSelect 
                        label="Qual a situação do pneu?"
                        options={tireConditionsOptions}
                        value={solicitation.tiresConfig.tire_conditions}
                        style={{marginBottom: 6, marginTop: 8}}
                        onChange={(event) => dispatch(changeTiresConfig({
                            column: "tire_conditions", 
                            value: event.target.value
                        }))}
                    />
                    <InputSelect 
                        label="Você possui ferramentas?"
                        options={hasSteppeOptions}
                        value={solicitation.tiresConfig.has_tools}
                        style={{marginBottom: 6, marginTop: 8}}
                        onChange={(event) => dispatch(changeTiresConfig({
                            column: "has_tools", 
                            value: event.target.value
                        }))}
                    />
                    <InputSelect 
                        label="Você possui estepe?"
                        options={hasSteppeOptions}
                        value={solicitation.tiresConfig.has_steppe}
                        style={{marginBottom: 6, marginTop: 8}}
                        onChange={(event) => dispatch(changeTiresConfig({
                            column: "has_steppe", 
                            value: event.target.value
                        }))}
                    />
                    {solicitation.tiresConfig.has_steppe === "sim" &&
                        <InputSelect 
                            label="O estepe está cheio?"
                            options={hasSteppeOptions}
                            style={{marginBottom: 6}}
                            value={solicitation.tiresConfig.steppe_is_full}
                            onChange={(event) => dispatch(changeTiresConfig({
                                column: "steppe_is_full", 
                                value: event.target.value
                            }))}
                        />
                    }
                </div>
            }
            {solicitation.service === "chaveiro" &&
                <div className='leads-solicitation-forms-item'>
                    <span>Como está a situação das chaves?</span>
                    <InputSelect 
                        label="Qual a situação da chave?"
                        options={keyConditionsOptions}
                        style={{marginBottom: 6, marginTop: 8}}
                        value={solicitation.keyConfig.condition}
                        onChange={(event) => dispatch(changeKeyConfig({
                            column: "condition", 
                            value: event.target.value
                        }))}
                    />
                    {solicitation.keyConfig.condition === "quebrada" &&
                        <InputSelect 
                            label="Você possui chave reserva?"
                            options={hasExtraKeyOptions}
                            style={{marginBottom: 6, marginTop: 8}}
                            value={solicitation.keyConfig.hasExtrakey}
                            onChange={(event) => dispatch(changeKeyConfig({
                                column: "hasExtrakey", 
                                value: event.target.value
                            }))}
                        />
                    }
                </div>
            }
            {solicitation.service === "reboque" &&
                <div className='leads-solicitation-forms-item'>
                    <span>Como está a situação das rodas?</span>
                    <InputSelect 
                        label="Qual a situação das rodas?"
                        options={wheelsConditionsOptions}
                        style={{marginBottom: 6, marginTop: 8}}
                        value={solicitation.wheelsConfig.condition}
                        onChange={(event) => dispatch(changeWheelsConditions({
                            column: "condition", 
                            value: event.target.value
                        }))}
                    />
                </div>
            }
            <Button 
                loading={loadginCreateSolicitation}
                label="Criar solicitação"
                onClick={createSolicitation}
                style={{width: "100%"}}
            />
        </div>
    )
}

export default NewSolicitation