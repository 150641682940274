import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushSolicitation } from "../../../action/solicitations";

import * as solicitationsService from '../../../services/solicitations'

import Layout from "../../../components/Layout";
import CustomTable from "../../../components/Table";

import './styles.css'
import Button from "../../../components/Button";
import { openModal } from "../../../action/modal";
import PriceCalculatorModal from "../../../components/Modal/PriceCalculor";
import OrderServiceModal from "../../../components/Modal/OrderService";
import ApproveServiceProposalModal from "../../../components/Modal/ApproveServiceProposal";

const Solicitations = () => {
    const dispatch = useDispatch()

    const solicitations = useSelector(state => state.solicitations.all)

    useEffect(() => {
        if (solicitations.values.length === 0) {
            (async() => {
                const response = await solicitationsService.getAllSolicitations({
                    page: 0,
                    rowsPerPage: 10
                })

                if (response) {
                    dispatch(pushSolicitation(response))
                }
            })()
        }
    }, [])

    return (
        <>
            <OrderServiceModal />
            <PriceCalculatorModal />
            <ApproveServiceProposalModal />
            <Layout>
                <div className="solicitation-container">
                    {solicitations.values.length > 0 &&
                        <>
                            <Button 
                                label="Calculadora"
                                style={{marginBottom: 8}}
                                onClick={() => dispatch(openModal({modal: "priceCalculator", value: true}))}
                            />
                            <CustomTable 
                                columns={solicitations.columns}
                                values={solicitations.values}
                                total={solicitations.total}
                                loading={solicitations.loading}
                            />
                        </>
                    }
                </div>
            </Layout>
        </>
    );
}

export default Solicitations;