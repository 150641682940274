import { createSlice } from '@reduxjs/toolkit'

export const serviceProposalsSlice = createSlice({
    name: "serviceProposal",
    initialState: {
        selected: {}
    },
    reducers: {
        setServiceProposal: (state, action) => {
            state.selected =  action.payload
        },
        updateFee: (state, action) => {
            state.selected.fee = action.payload
            state.selected.price = Number(state.selected.priceProvider) + Number(action.payload)
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    updateFee,
    setServiceProposal
} = serviceProposalsSlice.actions

export default serviceProposalsSlice.reducer